import {
  confirmModalOpenEvtName,
  notificationCenterNotifyEvtName,
} from "constants/events-names";

import Backbone from "backbone";
import { SecureHttpClient } from "services/http-client-service";
import TenantService from "services/tenant-service";
import { layoutBuilderExport } from "constants/api-routes";
import { parseTemplate } from "./export-generate-template";
import { trigger } from "helpers/events";

const CustomExportTemplate = (editor, initConfig) => {
  const pfx = editor.getConfig("stylePrefix");
  const modal = editor.Modal;
  const codeViewer = editor.CodeManager.getViewer("CodeMirror").clone();
  let viewerEditor = codeViewer.editor;
  const $ = Backbone.$;

  // Init import button
  const btnImp = document.createElement("button");
  btnImp.type = "button";
  btnImp.innerHTML = initConfig.modalExportButton || "Exportar";
  btnImp.className = `${pfx}btn-prim ${pfx}btn-import`;

  return {
    run(editor, sender, opts = {}) {
      console.warn(` you are in my custom export template command`);
      sender && sender.set && sender.set("active", 0);
      const { documentId, dmLocal } = editor.getConfig("storageManager");
      const config = editor.getConfig();
      const modal = editor.Modal;
      const pfx = config.stylePrefix;
      this.cm = editor.CodeManager || null;

      if (!this.$editors) {
        const oHtmlEd = this.buildEditor("javascript", "hopscotch", "JSX");
        const oCsslEd = this.buildEditor("css", "hopscotch", "CSS");
        this.htmlEditor = oHtmlEd.el;
        this.cssEditor = oCsslEd.el;
        const $editors = $(`<div class="${pfx}export-dl"></div>`);
        $editors.append(oHtmlEd.$el).append(oCsslEd.$el).append(btnImp);
        this.$editors = $editors;
      }

      btnImp.onclick = (e) => {
        trigger(confirmModalOpenEvtName, {
          title: "Atenção",
          message: "Confirma a exportação do Layout ?",
          executor: (okCancel) =>
            new Promise((resolve, reject) => {
              if (okCancel === "ok") {
                if (!TenantService().IsTenantDefined) {
                  trigger(notificationCenterNotifyEvtName, {
                    message:
                      "Falha ao exportar o Layout! {TENANT_NOT_RECOGNIZED}",
                    type: "error",
                  });
                  resolve();
                } else {
                  SecureHttpClient.Post(layoutBuilderExport(documentId), {
                    JSX: parseTemplate(editor, opts, documentId, false, true),
                    Styles: editor.getCss(),
                    DmLocal: dmLocal,
                  })
                    .finally(() => {
                      resolve();
                      modal.close();
                      trigger(notificationCenterNotifyEvtName, {
                        message: "Layout exportado com sucesso!",
                        type: "success",
                      });
                    })
                    .catch(() => {
                      trigger(notificationCenterNotifyEvtName, {
                        message: "Falha ao exportar o Layout!",
                        type: "error",
                      });
                    });
                }
              } else {
                resolve();
              }
            }),
        });
      };

      modal
        .open({
          title: initConfig.modalExportTitle || config.textViewCode,
          content: this.$editors,
        })
        .getModel()
        .once("change:open", () => editor.stopCommand(this.id));

      const layoutJSX = parseTemplate(editor, opts, documentId);
      this.htmlEditor.setContent(layoutJSX);
      this.cssEditor.setContent(editor.getCss());
    },

    stop(editor) {
      const modal = editor.Modal;
      modal && modal.close();
    },

    buildEditor(codeName, theme, label) {
      const input = document.createElement("textarea");
      !this.codeMirror && (this.codeMirror = this.cm.getViewer("CodeMirror"));

      const el = this.codeMirror.clone().set({
        label,
        codeName,
        theme,
        input,
      });

      const $el = new this.cm.EditorView({
        model: el,
        config: this.cm.getConfig(),
      }).render().$el;

      el.init(input);

      return { el, $el };
    },
  };
};

export default CustomExportTemplate;
