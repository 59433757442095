import {
  storageLocal,
  storageRemote,
  storageLocalRemote,
  storageKeys,
} from "../consts";
import clock from "services/clock-service";

const StorageLocalRemote = (editor, config) => {
  const sm = editor.StorageManager;
  console.warn(`Registering ${storageLocalRemote} ...`);
  sm.add(storageLocalRemote, {
    store(data, clb, clbErr) {
      const remote = sm.get(storageRemote);
      const local = sm.get(storageLocal);
      //store current date to be used for both storage strategies(local and remote).
      data[storageKeys.delta] = clock.currentUTCDate;

      // ...
      local.store(
        data,
        () => {
          remote.store(data, clb, (err) => {
            // TODO. some error on remote side notify the user about it.
          });
        },
        clbErr
      );
    },

    load(keys, clb, clbErr) {
      const remote = sm.get(storageRemote);
      const local = sm.get(storageLocal);

      // load local afterwards remote
      local.load(
        [...keys, storageKeys.delta],
        (localData) => {
          remote.load(
            [...keys, storageKeys.delta],
            (remoteData) => {
              execution({ remoteData: remote, localData: local });
            },
            (err) => {
              execution({ null: remote, localData: local });
            }
          );
        },
        (err) => {
          clbErr(err);
        }
      );

      const execution = ({ remote, local }) => {
        if (remote[storageKeys.delta] !== local[storageKeys.delta]) {
          throw new Error("LAYOUT_BUILDER_DELTA_DIFF_LOCAL_AND_REMOTE");
        } else {
          clb(remote);
        }
      };
    },
  });
};

export default StorageLocalRemote;
