/**
 * Parse an given object to an array turning each property as a object of type
 * {key: string, value: any} inside of array.
 * ex.
 *  {prop1: 'value1', prop2: true} =>
 *  [{key: 'prop1', value: 'value1'}, {key: 'prop2', value: true}]
 * @param  {any} obj
 * @param  {boolean} keyAsLowerCased=false
 */
export function MapObjToArray(obj: any, keyAsLowerCased = false) {
    return Object.keys(obj).map(key => {
        const normalizedKey = keyAsLowerCased ? key.toLowerCase() : key
        return {
            key: normalizedKey,
            value: obj[key]
        }
    })
}

const scapeString = (str: string) => {
    // eslint-disable-next-line no-control-regex
    return `"${str.replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0')}"`
}

const validateIsString = (content: string | null | undefined | never) => {
    if (content === null || content === undefined) {
        return ''
    } else if (typeof content !== 'string') {
        throw new Error('CONTENT_IS_NOT_A_STRING')
    }
    return content
}

const specialAttrsJSX = [
    ['class', 'className'],
    ['for', 'htmlFor'],
    ['accesskey', 'accessKey'],
    ['allowfullscreen', 'allowFullScreen'],
    ['autoplay', 'autoPlay'],
    ['controlslist', 'controlsList'],
    ['disablepictureinpicture', 'disablePictureInPicture'],
    ['enable-background', 'enableBackground'],
    ['crossorigin', 'crossOrigin']
]

const patternMatchAttr = (attr: string) => ` ${attr}="([^"]+)"`
const patternReplaceAttr = (attr: string) => ` ${attr}="$1"`

const normalizeJSX = (content: string | null | undefined) => {
    return specialAttrsJSX.reduce((html, attrPair) => {
        return html.replaceAll(new RegExp(patternMatchAttr(attrPair[0]), 'ig'), (match, p1) => {
            if (p1 === 'true' || p1 === 'false') {
                return ` ${attrPair[1]}={${p1}}`
            }
            return ` ${attrPair[1]}="${p1}"`
            return patternReplaceAttr(attrPair[1])
        })
    }, validateIsString(content))
}

const removeId = new RegExp(/ id="([^"]+)"/g)
const removeIdsAttr = (content: string | null | undefined) => validateIsString(content).replaceAll(removeId, '')

const matchBodyTag = /<\/?body\.*[^>]*>/g
const removeBodyTag = (content: string | null | undefined) => validateIsString(content).replaceAll(matchBodyTag, '')

const patternMatchRouterDom = (path: string) => `:${path}([^/]*)`
const parseUrlTemplate = (template: string, data: any) => {
    const route = Object.getOwnPropertyNames(data).reduce((parsed: string, prop: string) => {
        return parsed.replace(new RegExp(patternMatchRouterDom(prop), 'ig'), `${data[prop]}`)
    }, template)
    return route.replaceAll(/\/:([^\/]*)/gm, '')
}

/**
 * Determine if a given value is an Object (not null)
 * @param  {unknown} value
 */
export function IsObject(value: unknown): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null
}

/**
 * Applies the parsing of classes removing any duplicate entries (case-sensitive)
 * @param  {string|null|undefined} klasses
 */

export const distinctClassNames = (klasses: string | null | undefined): string => {
    if (typeof klasses !== 'string') return ''
    return Array.from(
        new Set(
            klasses
                .trim()
                .split(' ')
                .filter(x => x !== '')
        )
    ).join(' ')
}

export const isNullOrEmpty = (value: string | undefined) =>
    value === null || value === undefined || value?.trim() === ''

export { scapeString, normalizeJSX, removeIdsAttr, removeBodyTag, parseUrlTemplate }
