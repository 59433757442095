import {
  countTraint,
  defaultDateFormatItems,
  defaultSizeLengthItems,
  defaultSizeMateriaItems,
  headerListShowTraint,
  headerListTextTrait,
  headlineLengthTraint,
  idsCategoriaTrait,
  idsTrait,
  legendImageLengthTraint,
  titleLengthTraint,
} from "./defaults";

import MateriaBox from "components/boxes/materia-box";
import { createId } from "grapesjs/src/utils/mixins";
import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";
import { logger } from "workbox-core/_private";

const dateFormatTraint = {
  id: createId(),
  type: "date-format-members",
  name: "date-format",
  label: "3.3 &#45; Formato da data",
  defaultValue: "",
};

const findIndexOfTrait = (_this, traitName) => {
  const retorno = _this.attributes.traits.models.findIndex(
    (x) => x.attributes.name === traitName
  );
  // console.warn(`findIndexOfTrait->${traitName}`, _this)
  return retorno;
};

const RegisterMateriaBox = (editor, config = null) => {
  editor.Components.addType("MateriaBox", {
    extend: "react-component",
    model: {
      defaults: {
        component: MateriaBox,
        modulePath: "@components/boxes/materia",
        fetcher: {
          path: "@/providers/materia-server-data-provider",
          moduleName: "MateriaDataProvider",
          method: "GetMateriaListAsync",
        },
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          "display-mode": "list-static",
          "origin-data": "latest",
          members: "T,D,I",
        },
        traits: [
          //{
          //  type: "pick-materia",
          //  label: "Meu custom label",
          //},
          {
            type: "select", // Type of the trait
            label: "1 &#45; Modo de exibição", // The label you will see in Settings
            name: "display-mode", // The name of the attribute/property to use on component
            options: [
              { id: "slider", name: "Slider" },
              { id: "list-static", name: "Lista estática" },
            ],
            at: 10,
          },
          {
            type: "select", // Type of the trait
            label: "2 &#45; Origem dados", // The label you will see in Settings
            name: "origin-data", // The name of the attribute/property to use on component
            options: [
              { id: "latest", name: "Útimas publicações" },
              { id: "random", name: "Aleatória" },
              { id: "category", name: "Últimas da categoria" },
              { id: "specific", name: "Específica(s)" },
            ],
            at: 20,
          },
          {
            type: customTraitsIds.MateriaBoxMembers,
            name: customTraitsIds.MateriaBoxMembers,
            label: false,
            at: 30,
          },
          {
            type: "checkbox",
            name: "use-btn-view-more",
            label: "4 &#45; Exibir botão Veja Mais",
            valueTrue: true, // Value to assign when is checked, default: `true`
            valueFalse: false, // Value to assign when is unchecked, default: `false`
            at: 40,
          },
        ],
      },
      init: function (...args) {
        const newId = createId();
        // console.log("createId()", newId);
        // console.log("this.getId()", this.getId());
        // console.log(
        //   "this.attributes.attributes.id",
        //   this?.attributes?.attributes?.id
        // );
        // console.log("this", this);

        const {
          "origin-data": originData,
          "display-mode": displayMode,
          size,
          id,
          members,
          "date-format": dateFormat,
          "length-title": lengthTitle,
          "length-headline": lengthHeadline,
          "length-legend-image": lengthLegendImage,
          "header-list-show": headerListShow,
          "header-list-text": headerListText,
        } = this.getAttributes();
        this.addAttributes({ id: id || this.getId() || newId });

        // #region Origin Data

        switch (originData?.toLowerCase()) {
          case "specific":
            this.removeTrait(countTraint.name);
            this.addTrait(idsTrait, {
              at: findIndexOfTrait(this, "origin-data") + 1,
            });
            break;
          default:
            this.removeTrait(idsTrait.name);
            this.addAttributes({
              size: size || defaultSizeMateriaItems,
            });
            this.addTrait(countTraint, {
              at: findIndexOfTrait(this, "origin-data") + 1,
            });
            break;
        }
        this.on("change:attributes:origin-data", this.handleOriginDataChange);

        // #endregion Origin Data

        // #region Members

        const arrMembers = members.toUpperCase().replace(/\s/g, "");
        let pos = 1;
        // Título
        if (arrMembers.includes("T")) {
          this.addAttributes({
            [titleLengthTraint.name]: lengthTitle || defaultSizeLengthItems,
          });
          this.addTrait(titleLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos += 1;
        } else {
          this.removeTrait(titleLengthTraint.name);
          this.removeAttributes(titleLengthTraint.name);
        }
        // Chamada
        if (arrMembers.includes("H")) {
          this.addAttributes({
            [headlineLengthTraint.name]:
              lengthHeadline || defaultSizeLengthItems,
          });
          this.addTrait(headlineLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos += 1;
        } else {
          this.removeTrait(headlineLengthTraint.name);
          this.removeAttributes(headlineLengthTraint.name);
        }
        // Data
        if (arrMembers.includes("D")) {
          this.addAttributes({
            "date-format": dateFormat || defaultDateFormatItems,
          });
          dateFormatTraint.defaultValue = dateFormat || defaultDateFormatItems;
          this.addTrait(dateFormatTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos += 1;
        } else {
          this.removeTrait(dateFormatTraint.name);
          this.removeAttributes(["date-format"]);
        }
        // Imagem
        if (arrMembers.includes("I") && arrMembers.includes("L")) {
          this.addAttributes({
            [legendImageLengthTraint.name]:
              lengthLegendImage || defaultSizeLengthItems,
          });
          this.addTrait(legendImageLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos += 1;
        } else {
          this.removeTrait(legendImageLengthTraint.name);
          this.removeAttributes(legendImageLengthTraint.name);
        }
        this.on("change:attributes:members", this.handleMembersChange);

        // #endregion Members

        // #region Display Mode

        switch (displayMode?.toLowerCase()) {
          case "list-static":
            // Header List Show
            this.addTrait(headerListShowTraint, {
              at: findIndexOfTrait(this, "display-mode") + 1,
            });
            this.addAttributes({
              [headerListShowTraint.name]: headerListShow || false,
            });
            if (headerListShow) {
              // Header List Text
              this.addTrait(headerListTextTrait, {
                at: findIndexOfTrait(this, "header-list-show") + 1,
              });
              this.addAttributes({
                [headerListTextTrait.name]: headerListText,
              });
            }
            break;
          default:
            // Header List Show
            this.removeTrait(headerListShowTraint.name);
            this.removeAttributes(headerListShowTraint.name);
            // Header List Text
            this.removeTrait(headerListTextTrait.name);
            this.removeAttributes(headerListTextTrait.name);
            break;
        }
        this.on(
          "change:attributes:header-list-show",
          this.handleHeaderListShowChange
        );
        this.on("change:attributes:display-mode", this.handleDisplayModeChange);

        // #endregion Display Mode
      },
      handleHeaderListShowChange() {
        const headerListShow = this.getAttributes()["header-list-show"];
        const comp = editor.getSelected();
        if (headerListShow) {
          // Header List Text
          this.addTrait(headerListTextTrait, {
            at: findIndexOfTrait(this, "header-list-show") + 1,
          });
        } else {
          this.removeTrait(headerListTextTrait.name);
          comp.removeAttributes([
            headerListShowTraint.name,
            headerListTextTrait.name,
          ]);
        }
      },
      handleDisplayModeChange() {
        const displayMode = this.getAttributes()["display-mode"];
        const comp = editor.getSelected();
        switch (displayMode?.toLowerCase()) {
          case "list-static":
            // Header List Show
            this.addTrait(headerListShowTraint, {
              at: findIndexOfTrait(this, "display-mode") + 1,
            });
            break;
          default:
            // Header List Show
            this.removeTrait(headerListShowTraint.name);
            // Header List Text
            this.removeTrait(headerListTextTrait.name);
            comp.removeAttributes([
              headerListShowTraint.name,
              headerListTextTrait.name,
            ]);
            break;
        }
      },
      handleMembersChange() {
        const comp = editor.getSelected();
        const members = this.getAttributes()["members"];
        const arrMembers = members?.toUpperCase().replace(/\s/g, "");
        let pos = 1;
        // Titulo
        if (arrMembers?.includes("T")) {
          comp.addAttributes({
            [titleLengthTraint.name]: defaultSizeLengthItems,
          });
          this.addTrait(titleLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos++;
        } else {
          this.removeTrait(titleLengthTraint.id);
          comp.removeAttributes([titleLengthTraint.name]);
        }
        // Chamada
        if (arrMembers?.includes("H")) {
          comp.addAttributes({
            [headlineLengthTraint.name]: defaultSizeLengthItems,
          });
          this.addTrait(headlineLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos++;
        } else {
          comp.removeAttributes([headlineLengthTraint.name]);
          this.removeTrait(headlineLengthTraint.id);
        }
        // Data
        if (arrMembers?.includes("D")) {
          comp.addAttributes({
            [dateFormatTraint.name]: defaultDateFormatItems,
          });
          dateFormatTraint.defaultValue = defaultDateFormatItems;
          this.addTrait(dateFormatTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos++;
        } else {
          comp.removeAttributes([dateFormatTraint.name]);
          this.removeTrait(dateFormatTraint.name);
        }
        // Imagem
        if (arrMembers?.includes("I") && arrMembers?.includes("L")) {
          comp.addAttributes({
            [legendImageLengthTraint.name]: defaultSizeLengthItems,
          });
          this.addTrait(legendImageLengthTraint, {
            at: findIndexOfTrait(this, "materia-box-members") + pos,
          });
          pos++;
        } else {
          comp.removeAttributes([legendImageLengthTraint.name]);
          this.removeTrait(legendImageLengthTraint.id);
        }
      },
      handleOriginDataChange() {
        const originData = this.getAttributes()["origin-data"];
        const comp = editor.getSelected();
        switch (originData?.toLowerCase()) {
          case "specific":
            comp.removeAttributes([countTraint.name, idsCategoriaTrait.name]);
            comp.addAttributes({ [idsTrait.name]: "" });
            this.removeTrait(countTraint.id);
            this.removeTrait(idsCategoriaTrait.id);
            this.addTrait(idsTrait, {
              at: findIndexOfTrait(this, "origin-data") + 1,
            });
            break;
          default:
            comp.removeAttributes([idsTrait.name]);
            comp.addAttributes({ [countTraint.name]: defaultSizeMateriaItems });
            this.removeTrait(idsTrait.id);
            this.addTrait(countTraint, { at: 99 });
            if (originData.toLowerCase() === "category") {
              comp.addAttributes({ [idsCategoriaTrait.name]: "" });
              this.addTrait(idsCategoriaTrait, {
                at: findIndexOfTrait(this, "origin-data") + 2,
              });
            } else {
              this.removeTrait(idsCategoriaTrait.id);
              comp.removeAttributes([idsCategoriaTrait.name]);
            }
            break;
        }
      },
    },
    isComponent: (el) => el.tagName === "MATERIABOX",
  });

  editor.BlockManager.add("MateriaBox", {
    label: "<div class='gjs-fonts gjs-f-b1'>Materia Box</div>",
    category: "React Components",
    content: `<MateriaBox />`,
  });
};

export default RegisterMateriaBox;
