import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";
import { createId } from "grapesjs/src/utils/mixins";


const htmlContentBody = `
		<table class="table table-sm table-bordered table-dark">
			<thead>
				<tr>
					<th>Formato</th>
					<th>Descrição</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>yyyy ou yyy</td>
					<td>Ano com quatro digítos (por exemplo, 2023)</td>
				</tr>
				<tr>
					<td>yy ou y</td>
					<td>Ano com dois digítos (por exemplo, 23)</td>
				</tr>
				<tr>
					<td>MMMM</td>
					<td>Nome completo do mês (por exemplo, Dezembro)</td>
				</tr>
				<tr>
					<td>MMM</td>
					<td>Nome do mês abreviado (por exemplo, Dez)</td>
				</tr>
				<tr>
					<td>MM</td>
					<td>Número do mês com zero à esquerda (por exemplo, 03)</td>
				</tr>
				<tr>
					<td>M</td>
					<td>Número do mês (por exemplo, 3)</td>
				</tr>
				<tr>
					<td>dddd</td>
					<td>Representa o nome completo do dia (Segunda, Terça, Quarta, etc)</td>
				</tr>
				<tr>
					<td>ddd</td>
					<td>Representa o nome abreviado do dia (Seg, Ter, Qua, etc)</td>
				</tr>
				<tr>
					<td>dd</td>
					<td>Representa o dia do mês como um número de 01 a 31</td>
				</tr>
				<tr>
					<td>d</td>
					<td>Representa o dia do mês como um número de 1 a 31</td>
				</tr>
				<tr>
					<td>HH</td>
					<td>Hora do relógio de 24 horas, com um 0 à esquerda (por exemplo, 06)</td>
				</tr>
				<tr>
					<td>H</td>
					<td>Hora do relógio de 24 horas (por exemplo, 4)</td>
				</tr>
				<tr>
					<td>hh</td>
					<td>Hora do relógio de 12 horas, com um 0 à esquerda (por exemplo, 06)</td>
				</tr>
				<tr>
					<td>h</td>
					<td>Hora do relógio de 12 horas (por exemplo, 4)</td>
				</tr>
				<tr>
					<td>mm</td>
					<td>Minutos com zero à esquerda</td>
				</tr>
				<tr>
					<td>m</td>
					<td>Minutos</td>
				</tr>
				<tr>
					<td>ss</td>
					<td>Segundos com zero à esquerda</td>
				</tr>
				<tr>
					<td>s</td>
					<td>Segundos</td>
				</tr>
				<tr>
					<td>fff ou FFF</td>
					<td>Representa os três dígitos mais significativos da fração de segundos</td>
				</tr>
				<tr>
					<td>ff ou FFF</td>
					<td>Representa os dois dígitos mais significativos da fração de segundos</td>
				</tr>
				<tr>
					<td>f ou F</td>
					<td>Representa o dígito mais significativo da fração de segundos</td>
				</tr>
				<tr>
					<td>tt</td>
					<td>AM/PM (por exemplo, A ou P)</td>
				</tr>
				<tr>
					<td>t</td>
					<td>AM/PM abreviado (por exemplo, A ou P)</td>
				</tr> 
                <tr>
					<td>GMT</td>
					<td>Indicador hora de Greenwich</td>
				</tr>
			</tbody>
		</table>
`;


const registerDateFormatTrait = (editor, initialConfig) => {
    const { Modal } = editor;
    const createElementFromHTML = (htmlString) => {
        var div = document.createElement("div");
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes.
        return div.firstChild;
    };
    const handleCloseModal = (evt) => {
        evt.preventDefault();
        Modal.close();
    };

    const openModal = () => {
        const fragment = new DocumentFragment();

        const btnClose = createElementFromHTML('<button type="button" class="btn btn-primary">Fechar</button>');
        const content = createElementFromHTML("<div></div>");
        // contentBody
        content.appendChild(createElementFromHTML(htmlContentBody));
        content.appendChild(btnClose);
        btnClose.addEventListener("click", handleCloseModal);
        fragment.appendChild(content);
        fragment.appendChild(btnClose);

        Modal.open({
            title: "Formatos de data suportados", // string | HTMLElement
            content: fragment, // string | HTMLElement
        });
    };

    editor.TraitManager.addType(customTraitsIds.DateFormatMembers, {
        // Expects as return a simple HTML string or an HTML element
        createLabel({ label }) {
            const elLabel = document.createElement("div")
            elLabel.innerHTML = `${label} <a href="#" class="date-format-open-modal"><i class="bi bi-question-circle"></i></a>`;
            const inputType = elLabel.querySelector(".date-format-open-modal");
            // Let's make our content interactive
            inputType.addEventListener("click", (ev) => {
                ev.preventDefault();
                const element = ev.target;
                openModal();
            });
            return elLabel
        },
        // Expects as return a simple HTML string or an HTML element
        createInput({ trait }) {
            const el = document.createElement("div");
            el.setAttribute("id", "div-date-format");
            el.innerHTML = `
                 <div class="text-next__url-inputs">
                    <input class="input__date-format" type="text" name="date-format" placeholder="ex: dd/MM/yyyy">
                </div>
            `;

            // Let's make our content interactive
            const inputDate = el.querySelector('.input__date-format');
            inputDate.value = trait.attributes.defaultValue

            return el;
        },
        eventCapture: ["input"], // you can use multiple events in the array
        onEvent({ elInput, component, event }) { 
            component.addAttributes({ "date-format": this.$el.find("input[name=date-format]")[0].value }); 
        },

    });

};

export default registerDateFormatTrait;
