import { TenantIdHeaderKey } from 'constants/headers-keys'
import authService from './authorize-service'
import { currentTenantKey } from 'constants/session-storage-keys'

const contentType = {
    FormUrlEncoded: 'application/x-www-form-urlencoded;charset=UTF-8'
}

const parseObjAsUrlEncoded = (data: any) => {
    let formBodyItems = []
    for (var property in data) {
        const encodedKey = encodeURIComponent(property)
        const encodedValue = encodeURIComponent(data[property])
        formBodyItems.push(encodedKey + '=' + encodedValue)
    }
    const formBody = formBodyItems.join('&')
    return formBody
}

const SecureHttpClient = {
    async GetDefaultHeaders(): Promise<Record<string, string>> {
        const token = await authService.getAccessToken()
        const jsonPayload = window.sessionStorage.getItem(currentTenantKey)
        const currentTenant = jsonPayload ? JSON.parse(jsonPayload) : null

        return {
            ...(!token ? {} : { Authorization: `Bearer ${token}` }),
            ...(!currentTenant ? {} : { [TenantIdHeaderKey]: currentTenant.id }),
            'Content-Type': 'application/json'
        }
    },
    async Get<T = unknown>(url: string) {
        const response = await fetch(url, {
            method: 'GET',
            headers: await this.GetDefaultHeaders()
        })
        return (await response.json()) as T
    },
    async Post(url: string, data: BodyInit | object | undefined = undefined, overridesHeaders = {}) {
        const defaultHeaders = await this.GetDefaultHeaders()
        return fetch(url, {
            method: 'POST',
            body: typeof data === 'string' || data instanceof FormData ? data : JSON.stringify(data || {}),
            headers: { ...defaultHeaders, ...overridesHeaders }
        })
    }
}

const AnonymousHttpClient = {
    async Get(url: string) {
        const response = await fetch(url, {
            method: 'GET'
        })
        return response
    },
    async Post(url: string, data: any) {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data || {})
        })
        return await response.json()
    }
}

export { AnonymousHttpClient, SecureHttpClient, contentType, parseObjAsUrlEncoded }
