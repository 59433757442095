/**
 * Parse the composition of BannerBox or includes all members in case is not specified the composition
 * @param  {string|undefined|null} membersAsString
 */
export const parseComposition = (
  membersAsString: string | undefined | null
) => {
  const members =
    typeof membersAsString === "string"
      ? membersAsString.toUpperCase().replace(/\s/g, "").split(",")
      : [];
  const membersNotDefined = members.length === 0;
  return {
    hasIcon: membersNotDefined || members.includes("I"),
    hasText: membersNotDefined || members.includes("T"),
  };
};
