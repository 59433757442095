import React, { PropsWithChildren } from 'react'

import { EvolutionProgressStepStatusType } from 'types/components/common/evolution-progress-bar'
import cn from 'classnames'

const EvolutionProgressBarLabel: React.FC<PropsWithChildren<{ status: EvolutionProgressStepStatusType }>> = ({
    status
}) => {
    const getStatusLabel = (status: EvolutionProgressStepStatusType) =>
        status === 'done'
            ? ' Concluído'
            : status === 'error'
              ? ' Falha'
              : status === 'pending'
                ? ' Pendente'
                : status === 'running'
                  ? ' Executando...'
                  : status === 'aborted'
                    ? ' Interrompido'
                    : ''
    return (
        <p
            className={cn('h6 text-muted mb-0 mb-lg-0', {
                loading: status === 'running',
                'color-done': status === 'done',
                'color-error': status === 'error' || status === 'aborted',
                'color-running': status === 'running',
                'color-pending': status === 'pending'
            })}
        >
            {status === 'done' ? (
                <i className="bi bi-check-circle"></i>
            ) : status === 'error' || status === 'aborted' ? (
                <i className="bi bi-exclamation-circle"></i>
            ) : status === 'running' ? (
                <i className="bi bi-gear "></i>
            ) : status === 'pending' ? (
                <i className="bi bi-hourglass"></i>
            ) : (
                ''
            )}
            {getStatusLabel(status)}
        </p>
    )
}

export default EvolutionProgressBarLabel
