import { storageLocal, storageKeys } from "../consts";
import clock from "services/clock-service";

const local = window.sessionStorage;

const StorageLocal = (editor, config) => {
  const sm = editor.StorageManager;
  console.warn(`Registering ${storageLocal} ...`);
  sm.add(storageLocal, {
    /**
     * Load the data
     * @param  {Array} keys Array containing values to load, eg, ['gjs-components', 'gjs-styles', ...]
     * @param  {Function} clb Callback function to call when the load is ended
     * @param  {Function} clbErr Callback function to call in case of errors
     */
    load(keys, clb, clbErr) {
      const result = {};

      keys.forEach((key) => {
        const value = local[key];
        if (value) {
          result[key] = value;
        }
      });

      // Might be called inside some async method
      clb(result);
    },

    /**
     * Store the data
     * @param  {Object} data Data object to store
     * @param  {Function} clb Callback function to call when the load is ended
     * @param  {Function} clbErr Callback function to call in case of errors
     */
    store(data, clb, clbErr) {
      for (let key in data) {
        local[key] = data[key];
      }
      local[storageKeys.delta] =
        data[storageKeys.delta] || clock.currentUTCDate;
      // Might be called inside some async method
      clb();
    },
  });
};

export default StorageLocal;
