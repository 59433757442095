import MenuPortal from "components/common/menu";
import { createId } from "grapesjs/src/utils/mixins";
import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";

const RegisterMenu = (editor, config = null) => {
  editor.Components.addType("MenuPortal", {
    extend: "react-component",
    model: {
      defaults: {
        component: MenuPortal,
        modulePath: "@components/common/menu",
        fetcher: {
          path: "@/providers/menu-server-data-provider",
          moduleName: "MenuDataProvider",
          method: "GetMenuListAsync",
        },
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          members: "T,I",
        },
        traits: [
          {
            type: customTraitsIds.MenuMembers,
            label: "Membros",
          },
          {
            type: "text", // If you don't specify the type, the `text` is the default one
            name: "local", // Required and available for all traits
            label: "Local", // The label you will see near the input
            // label: false, // If you set label to `false`, the label column will be removed
            placeholder: "Ex. Principal, Lateral", // Placeholder to show inside the input
          },
        ],
      },
      init: function (...args) {
        const newId = createId();

        const { id } = this.getAttributes();
        this.addAttributes({ id: id || this.getId() || newId });
      },
    },
    isComponent: (el) => el.tagName === "MENUPORTAL",
  });

  editor.BlockManager.add("MenuPortal", {
    label: "<div class='gjs-fonts gjs-f-b1'>Menu</div>",
    category: "React Components",
    content: `<MenuPortal />`,
  });
};

export default RegisterMenu;
