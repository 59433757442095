import { Component } from "react";
import { Container } from "reactstrap";
import authService from "services/authorize-service";

export class FetchData extends Component {
  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <Container>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Date</th>
              <th>Temp. (C)</th>
              <th>Temp. (F)</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            {forecasts.map((forecast) => (
              <tr key={forecast.Date}>
                <td>{forecast.Date}</td>
                <td>{forecast.TemperatureC}</td>
                <td>{forecast.TemperatureF}</td>
                <td>{forecast.Summary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Aguarde...</em>
      </p>
    ) : (
      FetchData.renderForecastsTable(this.state.forecasts)
    );

    return (
      <div>
        <h1 id="tabelLabel">Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

  async populateWeatherData() {
    const token = await authService.getAccessToken();
    const response = await fetch("weatherforecast", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
