import { Button, Container } from 'reactstrap'
import React, { useState } from 'react'

import ConfirmDialog from '../components/dialog/confirm-dialog'
import { QueryParameterNames } from '../constants/api-authorization'
import TenantSelect from '../components/tenant/tenant-select'
import { confirmDialogMsg } from '../constants/messages'
import useCurrentTenant from '../hooks/use-current-tenant'

const SelectTenantPage = () => {
    const { current: stored, switchTenant: commit } = useCurrentTenant()
    const [preSelectedTenant, setPreSelectedTenant] = useState(null)

    const [isOpenModalConfirmation, setIsOpenModalConfirmation] = React.useState(false)

    const [isProcessing, setIsProcessing] = React.useState(false)

    const handlePreSelectedTenantChange = tenant => setPreSelectedTenant(tenant)

    const handleCloseConfirmModal = () => setIsOpenModalConfirmation(false)

    const commitSelectedTenant = () => {
        setIsProcessing(true)
        //here we are simulating an asynchronous operation, that can be an api request etc.
        commit(preSelectedTenant)
        setIsProcessing(false)
        setIsOpenModalConfirmation(false)
        navigateToReturnUrl(returnUrl() || '/')
    }

    const returnUrl = () => {
        const params = new URLSearchParams(window.location.search)
        const fromQuery = params.get(QueryParameterNames.ReturnUrl)
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.')
        }
        return fromQuery
    }

    const navigateToReturnUrl = returnUrl => {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl)
    }

    const handleOkCancel = (e, type) => {
        switch (type.toLowerCase()) {
            case 'ok':
                commitSelectedTenant()
                break
            case 'cancel':
                setIsOpenModalConfirmation(false)
                break
            default:
                break
        }
    }

    const showConfirmation = React.useCallback(() => {
        setIsOpenModalConfirmation(true)
    }, [setIsOpenModalConfirmation])

    return (
        <Container>
            <h1>Selecione um Cliente antes de continuar</h1>
            <TenantSelect handleChange={handlePreSelectedTenantChange} value={preSelectedTenant?.id} />
            <ConfirmDialog
                isOpen={isOpenModalConfirmation}
                handleClose={handleCloseConfirmModal}
                handleOkCancel={handleOkCancel}
                isProcessing={isProcessing}
                title="Atenção"
                message={confirmDialogMsg}
            />
            <Button
                className="mt-3"
                color="primary"
                disabled={preSelectedTenant === null || preSelectedTenant.id === stored?.id}
                onClick={showConfirmation}
            >
                Confirmar
            </Button>
        </Container>
    )
}

export default SelectTenantPage
