import { Connector } from './base-signalr-connection'
import { CreationProgressModel } from 'types/hubs/tenant-creation-progress-model'
import TenantService from '../../services/tenant-service'

class TenantCreationConnector extends Connector {
    static instance: TenantCreationConnector
    constructor() {
        super('/tenant-creation')
    }
    public static getInstance(): TenantCreationConnector {
        if (!TenantCreationConnector.instance) TenantCreationConnector.instance = new TenantCreationConnector()
        return TenantCreationConnector.instance
    }

    public OnCreationProgressChanged(callback: (model: CreationProgressModel) => void): void {
        this.SubscribeTo('CreationProgressChanged', callback)
    }

    public UnSubscribeToOnCreationProgressChanged(callback: (model: CreationProgressModel) => void): void {
        this.UnSubscribeTo('CreationProgressChanged', callback)
    }

    public OnReceiveSystemMessage(callback: (model: unknown) => void): void {
        this.SubscribeTo('ReceiveSystemMessage', callback)
    }

    public WatchStep(step: number) {
        const tenantId = TenantService().TenantId
        this.Invoke('WatchStep', step, tenantId)
    }
}
export default TenantCreationConnector.getInstance
