import React, { SyntheticEvent } from 'react'
import { SecureHttpClient, contentType, parseObjAsUrlEncoded } from 'services/http-client-service'
import { confirmRemoveLayout, confirmSetInUseLayout } from '../constants/messages'
import { layoutBuilderDelete, layoutBuilderList, layoutBuilderSetInUse, menuBuilderUpdate } from 'constants/api-routes'

import ConfirmDialog from '../components/dialog/confirm-dialog'
import { Container } from 'reactstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { layoutBuilderInsertUpdateRouter } from 'constants/app-routes'
import { parseUrlTemplate } from 'utils/strings-utils'

const enum DmLocal {
    Default = 1,
    Interno = 2
}

const dmLocalMapping = {
    [DmLocal.Default]: 'Default',
    [DmLocal.Interno]: 'Interno'
}

declare type LayoutConfig = {
    LayoutId: string
    DmLocal: DmLocal
    CreatedAtUtc?: string
    UpdatedAtUtc?: string
    InUse?: boolean
}

declare type CallbackActionType = { action: string; item: LayoutConfig }
declare type StateType = { data?: Array<LayoutConfig>; loading: boolean }

export const MenuBuilderList = () => {
    const [state, setState] = React.useState<StateType>({
        loading: true
    })

    const [isOpenModalConfirmation, setIsOpenModalConfirmation] = React.useState(false)

    const [isProcessing, setIsProcessing] = React.useState(false)
    const [callbackAction, setCallbackAction] = React.useState<CallbackActionType | null>(null)
    const [confirmDialogMsg, setConfirmDialogMsg] = React.useState('')

    const handleCloseConfirmModal = React.useCallback(() => {
        setIsOpenModalConfirmation(false)
        setCallbackAction(null)
    }, [setIsOpenModalConfirmation, setCallbackAction])

    const confirmAction = (action: 'setInUse' | 'delete', item: LayoutConfig) => {
        switch (action) {
            case 'delete':
                setConfirmDialogMsg(confirmRemoveLayout)
                setIsOpenModalConfirmation(true)
                break
            case 'setInUse':
                setConfirmDialogMsg(confirmSetInUseLayout)
                setIsOpenModalConfirmation(true)
                break
        }

        setCallbackAction({
            action,
            item
        })
    }

    const handleOkCancel = (e: SyntheticEvent, type: string) => {
        switch (type.toLowerCase()) {
            case 'ok':
                handleLayoutItemAction()
                break
            case 'cancel':
                setIsOpenModalConfirmation(false)
                setCallbackAction(null)
                break
            default:
                break
        }
    }

    React.useEffect(() => {
        const populateData = async () => {
            const data = await SecureHttpClient.Get<LayoutConfig[] | undefined>(layoutBuilderList)
            setState({ data, loading: false })
        }
        populateData()
    }, [setState])

    const handleLayoutItemAction = async () => {
        if (callbackAction === null) return
        const { action, item } = callbackAction
        switch (action) {
            case 'delete':
                setIsProcessing(true)
                await SecureHttpClient.Post(layoutBuilderDelete, parseObjAsUrlEncoded({ layoutId: item.LayoutId }), {
                    'Content-Type': contentType.FormUrlEncoded
                })
                    .then(() => {
                        setState(prev => ({
                            loading: prev.loading,
                            data: prev.data?.filter(e => e.LayoutId !== item.LayoutId)
                        }))
                    })
                    .catch(() => {})
                    .finally(() => {
                        setIsProcessing(false)
                        setIsOpenModalConfirmation(false)
                    })
                break
            case 'setInUse':
                setIsProcessing(true)
                await SecureHttpClient.Post(layoutBuilderSetInUse, parseObjAsUrlEncoded({ layoutId: item.LayoutId }), {
                    'Content-Type': contentType.FormUrlEncoded
                })
                    .then(() => {
                        setIsProcessing(false)
                        setIsOpenModalConfirmation(false)
                    })
                    .then(() => window.location.reload())

                break
            default:
                console.warn('UNKNOWN_ACTION')
        }
    }

    const renderTable = (data: Array<LayoutConfig>) => {
        return (
            <table className="table table-striped" aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Local</th>
                        <th>Criado em</th>
                        <th>Atualizado em</th>
                        <th>Padrão</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row: LayoutConfig) => (
                        <tr key={row.LayoutId}>
                            <td>{row.LayoutId}</td>
                            <td>{dmLocalMapping[row.DmLocal]}</td>
                            <td>{row.CreatedAtUtc ? new Date(row.CreatedAtUtc).toLocaleString() : '--/--/---'}</td>
                            <td>{row.UpdatedAtUtc ? new Date(row.UpdatedAtUtc).toLocaleString() : '--/--/---'}</td>
                            <td>{row.InUse ? 'Sim' : 'Não'}</td>
                            <td>
                                <DropdownButton id="dropdown-basic-button" title="Ações" variant="secondary">
                                    <Dropdown.Item
                                        href={parseUrlTemplate(layoutBuilderInsertUpdateRouter, {
                                            action: 'edit',
                                            dmLocal: row.DmLocal,
                                            documentId: row.LayoutId
                                        })}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item disabled={row.InUse} onClick={() => confirmAction('setInUse', row)}>
                                        Definir como padrão
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => confirmAction('delete', row)}>Apagar</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    let contents = state.loading ? (
        <p>
            <em>Aguarde...</em>
        </p>
    ) : (
        state.data && renderTable(state.data)
    )

    const hadleTest = async () => {
        console.log('Teste')
        const data = await SecureHttpClient.Post(menuBuilderUpdate('teste'))
        console.log('data', data)
    }

    return (
        <Container>
            <div>
                <ConfirmDialog
                    isOpen={isOpenModalConfirmation}
                    handleClose={handleCloseConfirmModal}
                    handleOkCancel={handleOkCancel}
                    isProcessing={isProcessing}
                    title="Atenção"
                    message={confirmDialogMsg}
                />
                <h1 id="tabelLabel">Menus</h1>
                <button onClick={hadleTest}>Testa API</button>
                <div>
                    <p>Selecione o Layout que deseja editar abaixo, ou</p>
                    <DropdownButton id="dropdown-basic-button" title="Criar novo">
                        <Dropdown.Item
                            href={parseUrlTemplate(layoutBuilderInsertUpdateRouter, {
                                action: 'new',
                                dmLocal: DmLocal.Default
                            })}
                        >
                            Default
                        </Dropdown.Item>
                        <Dropdown.Item
                            href={parseUrlTemplate(layoutBuilderInsertUpdateRouter, {
                                action: 'new',
                                dmLocal: DmLocal.Interno
                            })}
                        >
                            Interno
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
                {contents}
            </div>
        </Container>
    )
}

export default MenuBuilderList
