import React from 'react'
import { TenantCreationContextType } from '@/types/contexts/tenant-creation-context'

const TenantCreationContext = React.createContext<TenantCreationContextType>({
    isInsert: true
} as TenantCreationContextType)

export const TenantCreationProvider = TenantCreationContext.Provider
export const TenantCreationConsumer = TenantCreationContext.Consumer

export default TenantCreationContext
