import {
  ApplicationPaths,
  QueryParameterNames,
} from "constants/api-authorization";
import { Redirect, Route } from "react-router-dom";

import AppContext from "contexts/app-context";
import { Component } from "react";
import TenantService from "services/tenant-service";
import authService from "services/authorize-service";

export default class AuthorizeRoute extends Component {
  static contextType = AppContext;
  _tenantService;
  _defaultState = {
    ready: false,
    authenticated: false,
  };
  constructor(props) {
    super(props);
    this._tenantService = TenantService();
    this.state = Object.assign({}, this._defaultState);
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    const { currentTenant } = this.context;
    // console.log("currentTenant", currentTenant);
    const link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectLoginUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;

    const redirectSelectTenantUrl = `${ApplicationPaths.SelectTenant}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(this.getReturnUrl())}`;

    // console.log("redirectSelectTenantUrl", redirectSelectTenantUrl);

    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={(props) => {
            if (authenticated) {
              // console.log("authenticated", authenticated);
              if (
                this.props.path !== ApplicationPaths.SelectTenant &&
                (currentTenant === null || currentTenant === undefined)
              ) {
                // console.log("currentTenant", currentTenant);
                // console.log("redirectSelectTenantUrl", redirectSelectTenantUrl);
                return <Redirect to={redirectSelectTenantUrl} />;
              }

                return <Component {...props} />;
            } else {
              // console.log("redirectLoginUrl", redirectLoginUrl);
              return <Redirect to={redirectLoginUrl} />;
            }
          }}
        />
      );
    }
  }

  getReturnUrl() {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }
    const link = document.createElement("a");
    link.href = this.props.path;
    const fromPathProp = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;

    return fromQuery || fromPathProp;
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState(Object.assign({}, this._defaultState));
    await this.populateAuthenticationState();
  }
}
