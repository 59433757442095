import { normalizeJSX, removeBodyTag, scapeString } from "utils/strings-utils";

import TenantService from "services/tenant-service";

const isReactComponent = (comp) => comp.isInstanceOf(`react-component`);
const isVideoElement = (comp) => getComponentTagName(comp) === "video";
const getComponentName = (comp) => comp.get(`type`);
const getComponentPath = (comp) => comp.get(`modulePath`);
const getComponentTagName = (comp) => comp.get("tagName");
const getFecher = (comp) => comp.get(`fetcher`);

const walkThroughTree = (comp, opts) => {
  if (comp && isReactComponent(comp)) {
    const componentName = getComponentName(comp);
    // this component will be replaced by {props.children} bellow, so we don't need import it
    if (componentName !== "InternalContent") {
      opts.imports.add(
        `import ${componentName} from "${getComponentPath(comp)}";`
      );
    }

    const { tenantId, documentId } = opts;
    comp.addAttributes({ tenantId, documentId });

    const fetcher = getFecher(comp);

    if (fetcher) {
      opts.imports.add(`import ${fetcher.moduleName} from "${fetcher.path}";`);
      opts.fetchers.add({
        ...fetcher,
        id: comp.getId(),
        attrs: comp["attributes"],
      });
    }
  } else if (comp && isVideoElement(comp)) {
    // autoplay property of video has no value, so we need to set a value for be replaced by normalizerJSX
    comp.setAttributes({ autoplay: "true" });
  }
  const childrenComponents = comp.components();
  if (comp && childrenComponents.length > 0) {
    return comp.components().reduce((acc, current) => {
      return walkThroughTree(current, acc);
    }, opts);
  }

  return opts;
};

const template = `
    /* eslint-disable */
    import React from "react";
    import { AppProvider } from '@/contexts/app-context';
    {imports}

    {tenantId}
    {layoutId}

    const Layout = (props) => {
      return (
        <AppProvider value={{data: props.data, tenantId, layoutId}}>
          {jsx}
          {styles}
        </AppProvider>
      );
    }

    export async function getStaticPaths() {
      return {
          paths: [],
          fallback: 'blocking' // fallback true allows sites to be generated using ISR
      }
    }

    export async function getStaticProps({ params: { site } }) {
      /*
      * Neste ponto precisamos saber de antemão, qual seriam os componentes a serem utilizados pela página(Component Default Exportado), para passarmos os dados para ela através do objeto retornado abaixo na propriedade "props"
      *
      * */
      {fetchers}
      return {
          props: {
              data
          },
          revalidate: 10 // set revalidate interval of 3600(1h)
      }
    }
    Layout.getTenantId = () => tenantId;
    Layout.getLayoutId = () => layoutId;

    export default Layout;
    /* eslint-enable */`;

const getDeps = (editor, _, tenantId, documentId) => {
  const components = editor.getComponents();
  const dependencies = components.reduce(
    (acc, current) => {
      return walkThroughTree(current, acc);
    },
    { imports: new Set(), fetchers: new Set(), tenantId, documentId }
  );
  const imports = Array.from(dependencies.imports).join("\r\n");
  const fetchers = Array.from(dependencies.fetchers);

  return { imports, fetchers };
};

const getJSX = (editor, opts) =>
  removeBodyTag(normalizeJSX(editor.getHtml(opts) || ""));

const getStyles = (editor, appendStyles) => {
  if (!appendStyles) return "";
  return `<style jsx>{\`${editor.getCss()}\`}</style>`;
};

const getFetchers = (fetchers, tenantId, layoutId) => {
  const ids = [];
  const promises = fetchers.map((fetcher) => {
    ids.push(`"${fetcher.id}"`);
    return `${fetcher.moduleName}.${fetcher.method}(${JSON.stringify({
      ...((fetcher.attrs && fetcher.attrs.attributes) || {}),
      ...{ tenantId, layoutId },
    })})`;
  });

  const code = `
    const ids = [${ids.join(", ")}];
    const data = await Promise.allSettled([
    ${promises.join(", ")}
    ]).then(p => p.reduce((acc, current, index) => {
      if(current.status === 'fulfilled'){
        acc[ids[index]] = current.value
      }      
      return acc;
    }, {}));
`;

  return code;
};

export const parseTemplate = (
  editor,
  opts,
  documentId,
  scaped = false,
  appendStyles = false,
  appendTenantId = false
) => {
  const tenantId = TenantService().TenantId;
  const deps = getDeps(editor, opts, tenantId, documentId);
  const parsedTemplate = template
    .replace("{documentId}", documentId)
    .replace("{tenantId}", `const tenantId = '${tenantId}'`)
    .replace("{layoutId}", `const layoutId = '${documentId}'`)
    .replace("{imports}", deps.imports)
    .replace(
      "{fetchers}",
      deps.fetchers.length === 0
        ? "const data = {}"
          : getFetchers(deps.fetchers, tenantId, documentId)
    )
    .replace("{jsx}", getJSX(editor, opts))
    .replace(
      /<\s*InternalContent[^>]*>(.*?)<\s*\/\s*InternalContent>/g,
      "{props.children}"
    )
    .replace("{styles}", getStyles(editor, appendStyles));

  // apply prettier to format the template as expected by ESLint(otherwise will get errors)
  const prettierTemplate = window.prettier.format(parsedTemplate, {
    parser: "babylon",
    plugins: window.prettierPlugins,
    ...{
      trailingComma: "none",
      tabWidth: 4,
      semi: false,
      singleQuote: true,
      arrowParens: "avoid",
      endOfLine: "auto",
      printWidth: 120,
    },
  });

  return scaped ? scapeString(prettierTemplate) : prettierTemplate;
};
