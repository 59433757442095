export const tenantInsertUpdateRoute = '/tenant/:action(new|edit|new-subsite)',
    layoutBuilderInsertUpdateRouter = '/layout-builder/:action(new|edit)/:dmLocal/:documentId?',
    layoutBuilderListRouter = '/layout-builder/list',
    menuBuilderInsertUpdateRouter = '/menu-builder/:action(new|edit)/:dmLocal/:documentId?',
    menuBuilderListRouter = '/menu-builder/list',
    tenantCreationStep1 = '/tenant-creation/step1',
    tenantCreationStep2 = '/tenant-creation/step2',
    tenantCreationStep3 = '/tenant-creation/step3',
    tenantCreationStep4 = '/tenant-creation/step4',
    tenantCreationStep5 = '/tenant-creation/step5'
