import {
  confirmModalCloseRequestedEvtName,
  confirmModalClosedEvtName,
  confirmModalOpenEvtName,
} from "constants/events-names";
import { off, on, trigger } from "helpers/events";
import { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { SyntheticEvent } from "react";

const defaultTitle = "Confirmação";
const defaultMessage =
  "Confirma a execução da tarefa? Este processo não poderá ser desfeito posteriormente.";
const defaultAction = () =>
  function (okCancel: string) {
    return new Promise((resolve, reject) => {
      console.warn("No one executor was executed! It's a dummy callback.");
      resolve(null);
    });
  };

declare type ConfirmDialogProps = {
  handleClose: () => void;
  handleOkCancel: (
    e: SyntheticEvent<HTMLButtonElement>,
    okCancel: "ok" | "cancel"
  ) => void;
  isProcessing: boolean;
  titleProp: string;
  messageProp: string;
  closeOnClickBackdrop: boolean;
  closeOnEscKey: boolean;
};

export default function ConfirmDialog({
  closeOnClickBackdrop = false,
  closeOnEscKey = false,
}: ConfirmDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState({
    title: defaultTitle,
    message: defaultMessage,
    action: defaultAction(),
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const openModalFired = useCallback(
    (data: any) => {
      if (!isOpen) {
        const { title, message, executor } = (data && data.detail) || {};
        setSettings({
          title: title || defaultTitle,
          message: message || defaultMessage,
          action: executor || defaultAction(),
        });
        setIsOpen(true);
      }
    },
    [isOpen, setIsOpen, setSettings]
  );

  const closeRequested = useCallback(
    (data: any) => {
      if (!isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen]
  );

  const internalHandleOkCancel = async (
    e: SyntheticEvent<HTMLButtonElement>,
    okCancel: "ok" | "cancel"
  ) => {
    setIsProcessing(true);
    await settings.action(okCancel);
    setIsProcessing(false);
    setIsOpen(false);
    trigger(confirmModalClosedEvtName, {
      reason: okCancel,
    });
    console.log("internalHandleClose completed successfully!");
  };

  useEffect(() => {
    on(confirmModalOpenEvtName, openModalFired);
    return () => {
      off(confirmModalOpenEvtName, openModalFired);
    };
  }, [openModalFired]);

  useEffect(() => {
    on(confirmModalCloseRequestedEvtName, closeRequested);
    return () => {
      off(confirmModalCloseRequestedEvtName, closeRequested);
    };
  }, [closeRequested]);

  return (
    <Modal
      show={isOpen}
      backdrop={!closeOnClickBackdrop ? "static" : closeOnClickBackdrop}
      size="sm"
      keyboard={closeOnEscKey}
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>{settings.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{settings.message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={(e: SyntheticEvent<HTMLButtonElement>) =>
            internalHandleOkCancel(e, "cancel")
          }
          disabled={isProcessing}
        >
          Não
        </Button>
        <Button
          variant="primary"
          onClick={(e) => internalHandleOkCancel(e, "ok")}
          disabled={isProcessing}
        >
          {isProcessing && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          {isProcessing ? "Aguarde..." : "Sim"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
