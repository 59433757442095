import materiaDataProvider from "providers/materia-data-provider";

const tableTemplate = (
    data,
    hasNextPage,
    hasPreviousPage,
    totalCount,
    pageSize,
    currentPage
) => `<div class="container">
<div class="row">
  <div class="col-12">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Código</th>
          <th scope="col">Title</th>
        </tr>
      </thead>
      <tbody>
        ${data.map((materia) => {
    return `<tr>
          <td>
            <div class="custom-control custom-checkbox">
                <input value="${materia.CdMateria}" type="checkbox" class="custom-control-input" id="customCheck1" checked>
                <label class="custom-control-label" for="customCheck1">${materia.CdMateria}</label>
            </div>
          </td>
          <td>${materia.Title}</td>
        </tr>`;
})}
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-end">
        <li class="page-item"><a class="page-link ${hasPreviousPage ? "" : "disabled"
    }" href="#">Previous</a></li>
        ${new Array(
        Math.min(
            parseInt(totalCount / pageSize, 10) + (totalCount % pageSize) > 0
                ? 1
                : 0,
            3
        )
    ).map(
        (value, index) =>
            `
<li class="page-item ${currentPage == index ? "active" : ""}">
  <a class="page-link" href="#" data-page-number="${index + 1}">
    ${index + 1}
  </a>
</li>`
    )}
        <li class="page-item ${hasNextPage ? "" : "disabled"
    }"><a class="page-link" href="#">Next</a></li>
      </ul>
    </nav>
  </div>
</div>
</div>`;

const registerPickMateriaTrait = (editor, initialConfig) => {
    const { Modal } = editor;

    const createElementFromHTML = (htmlString) => {
        var div = document.createElement("div");
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes.
        return div.firstChild;
    };
    let currentPage = 1;
    const pageSize = 50;
    const handleRequestGraphQL = (evt) => {
        evt.preventDefault();
        console.log("CLicado!!!");
        materiaDataProvider
            .MateriaListPagedAsync({
                take: pageSize,
                skip: (currentPage - 1) * pageSize,
            })
            .then((response) => {
                console.log("response", response);
                currentPage++;
            });
    };

    const handleCloseModal = (evt) => {
        evt.preventDefault();
        Modal.close();
    };

    const openModal = () => {
        const fragment = new DocumentFragment();
        const btnRequest = createElementFromHTML(
            "<button id='request-graphql'>Request GraphQL</button>"
        );
        const btnClose = createElementFromHTML("<a href='#'>Fechar</a>");
        const title = createElementFromHTML("<h1>Sou um modal</h1>");
        const content = createElementFromHTML("<div></div>");
        content.appendChild(title);
        content.appendChild(btnClose);
        content.appendChild(btnRequest);
        btnClose.addEventListener("click", handleCloseModal);
        btnRequest.addEventListener("click", handleRequestGraphQL);
        fragment.appendChild(btnClose);
        fragment.appendChild(content);

        Modal.open({
            title: "My custom modal", // string | HTMLElement
            content: fragment, // string | HTMLElement
        });
    };

    editor.TraitManager.addType("pick-materia", {
        // Update the component based on element changes
        // `elInput` is the result HTMLElement you get from `createInput`
        // onEvent({ elInput, component, event }) {
        //   const inputType = elInput.querySelector(".href-next__type");
        //   let href = "";

        //   switch (inputType.value) {
        //     case "url":
        //       const valUrl = elInput.querySelector(".href-next__url").value;
        //       href = valUrl;
        //       break;
        //     case "email":
        //       const valEmail = elInput.querySelector(".href-next__email").value;
        //       const valSubj = elInput.querySelector(
        //         ".href-next__email-subject"
        //       ).value;
        //       href = `mailto:${valEmail}${valSubj ? `?subject=${valSubj}` : ""}`;
        //       break;
        //     default:
        //       break;
        //   }

        //   component.addAttributes({ href });
        // },
        // Expects as return a simple HTML string or an HTML element
        createLabel({ label }) {
            return `<div>
                      <div>Before</div>
                        ${label}
                      <div>After</div>
                    </div>`;
        },
        // Expects as return a simple HTML string or an HTML element
        createInput({ trait }) {
            // Here we can decide to use properties from the trait
            const traitOpts = trait.get("options") || [];
            const options = traitOpts.length
                ? traitOpts
                : [
                    { id: "url", name: "URL" },
                    { id: "email", name: "Email" },
                ];

            // Create a new element container and add some content
            const el = document.createElement("div");
            el.innerHTML = `<a href="#" class="pick-materia-open-modal">Open modal</a>`;

            // Let's make our content interactive
            const inputType = el.querySelector(".pick-materia-open-modal");
            inputType.addEventListener("click", (ev) => {
                ev.preventDefault();
                const element = ev.target;
                console.log("element", element);
                openModal();
            });

            return el;
        },

        // Update the component based on element changes
        // `elInput` is the result HTMLElement you get from `createInput`
        // onEvent({ elInput, component, event }) {
        //   const inputType = elInput.querySelector(".href-next__type");
        //   let href = "";

        //   switch (inputType.value) {
        //     case "url":
        //       const valUrl = elInput.querySelector(".href-next__url").value;
        //       href = valUrl;
        //       break;
        //     case "email":
        //       const valEmail = elInput.querySelector(".href-next__email").value;
        //       const valSubj = elInput.querySelector(
        //         ".href-next__email-subject"
        //       ).value;
        //       href = `mailto:${valEmail}${valSubj ? `?subject=${valSubj}` : ""}`;
        //       break;
        //     default:
        //       break;
        //   }

        //   component.addAttributes({ href });
        // },
        // Update elements on the component change
        // onUpdate({ elInput, component }) {
        //   const href = component.getAttributes().href || "";
        //   const inputType = elInput.querySelector(".href-next__type");
        //   let type = "url";

        //   if (href.indexOf("mailto:") === 0) {
        //     const inputEmail = elInput.querySelector(".href-next__email");
        //     const inputSubject = elInput.querySelector(".href-next__email-subject");
        //     const mailTo = href.replace("mailto:", "").split("?");
        //     const email = mailTo[0];
        //     const params = (mailTo[1] || "").split("&").reduce((acc, item) => {
        //       const items = item.split("=");
        //       acc[items[0]] = items[1];
        //       return acc;
        //     }, {});
        //     type = "email";

        //     inputEmail.value = email || "";
        //     inputSubject.value = params.subject || "";
        //   } else {
        //     elInput.querySelector(".href-next__url").value = href;
        //   }

        //   inputType.value = type;
        //   inputType.dispatchEvent(new CustomEvent("change"));
        // },
    });
};

export default registerPickMateriaTrait;
