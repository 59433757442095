import { EventHandler, MouseEvent, useEffect, useRef, useState } from 'react'

import { Container } from 'reactstrap'
import { SecureHttpClient } from 'services/http-client-service'
import { debounce } from 'lodash'

export interface RecordSetModel {
    RecordSet: RecordSet
    HostedZoneId: string
}

export interface RecordSet {
    Name: string
    Type: Type
    SetIdentifier: null
    Weight: number
    Region: null
    GeoLocation: null
    Failover: null
    MultiValueAnswer: boolean
    TTL: number
    ResourceRecords: Type[]
    AliasTarget: null
    HealthCheckId: null
    TrafficPolicyInstanceId: null
    CidrRoutingConfig: null
}

export interface Type {
    Value: string
}

export type RecordSetPayload = {
    Name: string
    Type: string
    Values: string[]
    TTL: number
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const Counter = (props: Record<string, string>) => {
    const hostedZonesRef = useRef<HTMLTextAreaElement | null>(null)
    const hostedZoneInputRef = useRef<HTMLInputElement | null>(null)
    const [recordsetData, setRecordSetData] = useState<RecordSetModel | null>(null)
    const [searchedRecordName, setSearchedRecordName] = useState<string>('')
    const [recordsetCreateData, setRecordSeCreatetData] = useState<string>(
        `
    {
      "Name": "teste-agora.portalfacil.srv.br",
      "Type": "CNAME",
      "Values": ["gact.portalfacil.srv.br"],
      "TTL": 302
    }
    `
    )

    const [changeInfoData, setChangeInfoData] = useState<Record<string, unknown> | null>(null)

    const [changeInfoId, setChangeInfoId] = useState<string | undefined>()
    const handleFindRecodSet = () => {
        if (!searchedRecordName) return
        SecureHttpClient.Get<RecordSetModel>(
            BASE_URL + `/api/Aws/FindRecordSet?name=${searchedRecordName}.&type=CNAME`
        ).then(d => {
            setRecordSetData(d)
        })
    }

    const handleListHostedZones = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        SecureHttpClient.Get<RecordSetModel>(BASE_URL + `/api/Aws/ListHostedZone`).then(d => {
            if (hostedZonesRef.current) {
                hostedZonesRef.current.value = JSON.stringify(d, null, 4)
            }
        })
    }

    const handleListResourcesOfHostedZone = (e: MouseEvent<HTMLButtonElement>, hostedZoneId: string | undefined) => {
        e.preventDefault()
        if (!hostedZoneId) return
        SecureHttpClient.Get<RecordSetModel>(BASE_URL + `/api/Aws/ListResources/${hostedZoneId.trim()}`).then(d => {
            if (hostedZonesRef.current) {
                hostedZonesRef.current.value = JSON.stringify(d, null, 4)
            }
        })
    }

    const handleCreate = () => {
        if (recordsetCreateData === null) return
        const data = JSON.parse(recordsetCreateData)
        console.log('createData', data)
        SecureHttpClient.Post(BASE_URL + '/api/Aws/CreateRecordSet', data).then(d => {
            console.log('handleCreate', d)
        })
    }

    const handlerDelete = () => {
        if (!recordsetData?.RecordSet) return
        const {
            Name,
            Type: { Value },
            ResourceRecords,
            TTL
        } = recordsetData.RecordSet
        const payload = {
            Name,
            Type: Value,
            Values: (ResourceRecords || []).map(x => x.Value),
            TTL
        } as RecordSetPayload
        SecureHttpClient.Post(BASE_URL + '/api/Aws/RemoveRecordSet', payload)
            .then(r => (r.ok ? r.json() : r))
            .then(d => {
                console.log(d)
            })
    }

    const handleGetChangeInfo = () => {
        setChangeInfoData(null)
        SecureHttpClient.Get<Record<string, unknown>>(
            BASE_URL + `/api/Aws/GetChangeStatus?changeInfoId=${encodeURIComponent(changeInfoId || '')}`
        ).then(d => {
            setChangeInfoData(d)
        })
    }
    useEffect(() => {
        console.log('changeInfoId', changeInfoId)
    }, [changeInfoId])
    return (
        <Container>
            teste-agora.portalfacil.srv.br
            <div>
                <input type="text" value={searchedRecordName} onChange={e => setSearchedRecordName(e.target.value)} />
                <button className="btn btn-primary" onClick={handleFindRecodSet}>
                    Buscar Record Set
                </button>
                {recordsetData && <pre>{JSON.stringify(recordsetData, null, 4)}</pre>}
            </div>
            <button className="btn btn-primary" onClick={handlerDelete}>
                Delete
            </button>
            <div>
                <input type="text" onChange={debounce(e => setChangeInfoId(e.target.value), 500)} />
                <button className="btn btn-primary" onClick={handleGetChangeInfo}>
                    GetStatus
                </button>
                {changeInfoData && <pre>{JSON.stringify(changeInfoData, null, 4)}</pre>}
            </div>
            <div>
                <textarea
                    rows={20}
                    cols={120}
                    value={recordsetCreateData}
                    onChange={e => setRecordSeCreatetData(e.target.value)}
                />
                <button className="btn btn-primary" onClick={handleCreate}>
                    Create
                </button>
            </div>
            <div>
                <textarea rows={20} cols={120} readOnly ref={hostedZonesRef} />
                <div>
                    <button className="btn btn-primary" onClick={handleListHostedZones}>
                        List hosted zones
                    </button>
                </div>
                <div>
                    <input type="text" placeholder="Hosted zone Id" ref={hostedZoneInputRef} />
                    <button
                        className="btn btn-primary"
                        onClick={e => handleListResourcesOfHostedZone(e, hostedZoneInputRef.current?.value)}
                    >
                        Buscar Record Set of Hosted zone
                    </button>
                    {recordsetData && <pre>{JSON.stringify(recordsetData, null, 4)}</pre>}
                </div>
            </div>
        </Container>
    )
}

export default Counter
