import { AnonymousHttpClient } from 'services/http-client-service'

export interface IWorldTimeAPIResponse {
    /*
  {
    "abbreviation": "-03",
    "client_ip": "131.161.95.239",
    "datetime": "2022-04-26T08:24:20.590877-03:00",
    "day_of_week": 2,
    "day_of_year": 116,
    "dst": false,
    "dst_from": null,
    "dst_offset": 0,
    "dst_until": null,
    "raw_offset": -10800,
    "timezone": "America/Sao_Paulo",
    "unixtime": 1650972260,
    "utc_datetime": "2022-04-26T11:24:20.590877+00:00",
    "utc_offset": "-03:00",
    "week_number": 17
  }
  */
    utc_datetime: string
}

export class Clock {
    private _date_utc: Date | null
    private _ticks: number
    constructor() {
        this._date_utc = null
        this._ticks = 0
        this.startTimer()
        const that = this

        AnonymousHttpClient.Get('https://worldtimeapi.org/api/timezone/America/Sao_Paulo')
            .then(async res => (await res.json()) as IWorldTimeAPIResponse)
            .then(obj => {
                that._date_utc = new Date(obj.utc_datetime)
                console.warn('DATE_LOADED_FROM_WORLDTIMEAPI')
            })
            .catch(e => {
                console.warn(`DATE_LOADED_FROM_WORLDTIMEAPI_ERROR: ${e}`)
            })
    }

    private startTimer() {
        const that = this
        setInterval(function () {
            that._ticks++
        }, 1000)
    }

    private checkAPIResponseStatus() {
        if (this._date_utc == null) {
            console.warn('NOT_POSSIBLE_TO_GET_DATA_FROM_WORLDTIMEAPI')
        }
    }

    get currentUTCDate() {
        this.checkAPIResponseStatus()
        return this._date_utc === null
            ? new Date().toISOString()
            : new Date(this._date_utc?.getTime() + this._ticks * 1000).toISOString()
    }
}
const clock = new Clock()
;(window as unknown as Record<string, any>)['_globalClock'] = clock
export default clock
