import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import React from "react";

declare type props = {
  isOpen: boolean;
  message?: string;
  handleButton?: () => Promise<void>;
};

const noop = () => new Promise((reject, resolve) => resolve());

const MessageDialog: React.FC<props> = ({ isOpen, message, handleButton }) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const internalHandleButton = React.useCallback(
    async function () {
      setIsProcessing(true);
      await (handleButton || noop)();
      setIsProcessing(false);
    },
    [setIsProcessing, handleButton]
  );

  return (
    <Modal
      isOpen={isOpen}
      backdrop="static"
      size="sm"
      centered={true}
      keyboard={false}
      scrollable={false}
    >
      <ModalBody>
        {isProcessing ? (
          <div>
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          <span>{message || ""}</span>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={(e) => internalHandleButton()}
          disabled={isProcessing}
        >
          {isProcessing && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          {isProcessing ? "Aguarde..." : "Ok"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageDialog;
