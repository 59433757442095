import { AtividadeItem, AtividadesPublico, MenuItemModel } from 'actcon-portal-types'

import type { MenuItemType } from 'types/common/menu-item'
import cn from 'classnames'
import { parseComposition } from 'utils/menu-utils'

const MenuItem: MenuItemType = ({
    Data: { IdAtividade, Cd, Cd2, Url, StAbrirExterno, StOcultaSubNivel, NmItem, Items },
    Members,
    Index,
    Level
}) => {
    const { hasText, hasIcon } = parseComposition(Members)
    const isParent = Items && Items.length > 0
    const isRoot = Level === 0
    const isNotFirstLevel = Level > 0
    const convertUrlFromLegacyToNewFormat = (
        idAtividade?: keyof AtividadesPublico | null,
        cd1?: number | null,
        cd2?: number | null
    ) => {
        if (idAtividade && AtividadesPublico[idAtividade]) {
            const atividade = AtividadesPublico[idAtividade] as AtividadeItem
            return `${atividade.Route}${(cd1 && `/${cd1}/slug1`) || ''}${(cd2 && `/${cd2}/slug2`) || ''}`
        }
        return null
    }

    return (
        <li className={cn({ 'nav-item': isRoot, dropdown: isParent && isRoot })}>
            <a
                target={(StAbrirExterno && '_blank') || '_self'}
                className={cn({
                    'dropdown-item': !isRoot,
                    'nav-link': isRoot,
                    'dropdown-toggle': isParent && isRoot
                })}
                href={
                    (isParent && '#') ||
                    convertUrlFromLegacyToNewFormat(IdAtividade as keyof AtividadesPublico, Cd, Cd2) ||
                    Url ||
                    ''
                }
                {...((isParent && isRoot && { 'data-bs-toggle': 'dropdown' }) || {})}
            >
                {hasText && NmItem}
            </a>
            {Items && Items.length > 0 && (
                <ul
                    className={cn('dropdown-menu', {
                        submenu: isNotFirstLevel,
                        'oculta-subnivel': StOcultaSubNivel
                    })}
                >
                    {Items.map((item: MenuItemModel, index: number) => (
                        <MenuItem key={item.IdItem} Data={item} {...{ Members, Index: index, Level: Level + 1 }} />
                    ))}
                </ul>
            )}
        </li>
    )
}

export default MenuItem
