import RegisterBannerBox from "./react-components/boxes/banner-box";
import RegisterHeadComponent from "./react-components/next-head-component";
import RegisterImageComponent from "./react-components/next-image-component";
import RegisterInternalContentComponent from "./react-components/internal-content-component";
import RegisterMateriaBox from "./react-components/boxes/materia-box";
import RegisterMenu from "./react-components/common/menu";
import RegisterRodape from "./react-components/common/rodape";
import RegisterScriptComponent from "./react-components/next-script-component";
import RegisterTranslate from "./react-components/common/translate";
import RegisterYouTubeBox from "./react-components/boxes/youtube-box";

const RegisterComponents = (editor) => {
  //Next Components
  RegisterHeadComponent(editor);
  RegisterScriptComponent(editor);
  RegisterYouTubeBox(editor);
  RegisterImageComponent(editor);

  //Shared Componentes
  RegisterInternalContentComponent(editor);
  RegisterMenu(editor);
  RegisterRodape(editor);
  RegisterTranslate(editor);

  // Boxes Components
  RegisterMateriaBox(editor);
  RegisterBannerBox(editor);
};

export default RegisterComponents;
