import { MenuItemModel, MenuModel } from 'actcon-portal-types'
import { useContext, useEffect, useRef, useState } from 'react'

import AppContext from 'contexts/app-context'
import { LoadMenuByLocal } from 'constants/api-routes'
import MenuItem from './menu-item'
import type { MenuType } from 'types/common/menu'
import { SecureHttpClient } from 'services/http-client-service'
import cn from 'classnames'
import { uniqueId } from 'lodash'

const MenuPortal: MenuType = ({ id, direction, members, local, className, ...rest }) => {
    const previousLocal = useRef<string | undefined>()
    const { data } = useContext(AppContext) as any
    const [items, setItems] = useState<MenuItemModel[]>((data && data[id]?.props?.data?.menu?.nodes) || undefined)
    const menuId = uniqueId()

    const refEle = useRef<HTMLElement | null>(null)
    // Lógica pra ser utilizada quando o componente for utilizado no contexto do BackOffice
    useEffect(() => {
        const fetchDataAsync = async (l: string) => {
            // TODO: Implementar lógica para o componente se autopreencher com dados baseados nas props
            const data = await SecureHttpClient.Get<MenuModel>(LoadMenuByLocal(local))
            if (data && data.Items && data.Items.length > 0) setItems(data.Items)
            else setItems([])
            previousLocal.current = l
        }
        if (local !== previousLocal.current) {
            fetchDataAsync(local)
        }
    }, [local])

    useEffect(() => {
        const minWidth = 992
        // make it as accordion for smaller screens
        if (refEle.current) {
            // close all inner dropdowns when parent is closed
            refEle.current.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown: Element) {
                everydropdown.addEventListener('hidden.bs.dropdown', function () {
                    // after dropdown is hidden, then find all submenus
                    everydropdown
                        .querySelectorAll<HTMLElement>('.submenu')
                        .forEach(function (everysubmenu: HTMLElement) {
                            if (window.innerWidth < minWidth) {
                                // hide every submenu as well
                                everysubmenu.style.display = 'none'
                            }
                        })
                })
            })

            refEle.current.querySelectorAll<HTMLAnchorElement>('.dropdown-menu a').forEach(function (element) {
                element.addEventListener('click', function (e) {
                    const nextEl = element.nextElementSibling as HTMLElement
                    if (nextEl && nextEl.classList.contains('submenu')) {
                        // prevent opening link if link needs to open dropdown
                        // e.preventDefault(); // is it necessary?
                        if (window.innerWidth < minWidth) {
                            if (nextEl.style.display === 'block') {
                                nextEl.style.display = 'none'
                            } else {
                                nextEl.style.display = 'block'
                            }
                        }
                    }
                })
            })
        }
        // end if innerWidth
    }, [items])

    return (
        (items && items.length > 0 && (
            <nav className={cn('py-2 navbar navbar-expand-lg', className)} ref={refEle}>
                <style>
                    {`
        /*
        //https://bootstrap-menu.com/detail-multilevel.html
        ============ CAUTION ============
        This rules are used for control the visibilty of sub items in BS nav menu in sub-levels
        In case these rules be removed the expected behavior will not work anymore
        */
        /* ============ desktop view ============ */
        @media all and (min-width: 992px) {
          .dropdown-menu li {
            position: relative;
          }
          .nav-item .submenu {
            display: none;
            position: absolute;
            left: 100%;
            top: -7px;
          }
          .nav-item .submenu-left {
            right: 100%;
            left: auto;
          }
          .dropdown-menu > li:hover > .submenu {
            display: block;
          }
        }
        /* ============ desktop view .end// ============ */

        /* ============ small devices ============ */
        @media (max-width: 991px) {
          .dropdown-menu .dropdown-menu {
            margin-left: 0.7rem;
            margin-right: 0.7rem;
            margin-bottom: 0.5rem;
          }
        }

        `}
                </style>
                <div className="collapse navbar-collapse" id="main_nav">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#navbar-portal-${menuId}`}
                        aria-controls="navbar-portal"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="bi bi-list"></i>
                    </button>
                    <ul id="menuId" className="navbar-nav container justify-content-center">
                        {items
                            .filter(item => item.IdItemPai === null)
                            .map((item, index) => (
                                <MenuItem key={item.IdItem} Data={item} Members={members} Index={index} Level={0} />
                            ))}
                    </ul>
                </div>
            </nav>
        )) || <h2>Sem dados para exibir</h2>
    )
}

export default MenuPortal
