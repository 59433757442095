import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";

const registerBannerBoxMemberTrait = (editor, initialConfig) => {
  editor.TraitManager.addType(customTraitsIds.BannerBoxMembers, {
    // Completely remove the wrapper
    templateInput: "",
    // Expects as return a simple HTML string or an HTML element
    createInput({ trait }) {
      const members = (editor.getSelected().getAttributes()["members"] || "")
        .toUpperCase()
        .replace(/\s/g, "")
        .split(",");
      const html = `<fieldset>
        <div>
          <label for="title">
            <input type="checkbox" value="T" name="title" ${
              members.includes("T") ? "checked" : ""
            } />
            <span>Título</span>
          </label>
        </div>
        <div>
          <label for="date">
            <input type="checkbox" value="D" name="date" ${
              members.includes("D") ? "checked" : ""
            } />
            <span>Data</span>
          </label>
        </div>
        <div>
          <label for="media">
            <input type="checkbox" value="I" name="media" ${
              members.includes("M") ? "checked" : ""
            } />
            <span>Imagem/Video</span>
          </label>
        </div>      
      </fieldset>
      `;
      return html;
    },
    eventCapture: ["input"], // you can use multiple events in the array
    onEvent({ elInput, component, event }) {
      let members = [];
      this.$el.find("input[type=checkbox]").each((index, ele) => {
        if (ele.checked) {
          members.push(ele.value);
        }
      });

      if (members.length > 0) {
        component.addAttributes({ members: members.join(",") });
      } else {
        component.removeAttributes(["members"]);
      }
    },
    // Update elements on the component change
    onUpdate({ elInput, component }) {
      const members = (component.getAttributes().members || "")
        .toUpperCase()
        .replace(/\s/g, "")
        .split(",");

      this.$el.find("input[type=checkbox]").each((index, ele) => {
        if (members.includes(ele.value)) {
          ele.checked = true;
        }
      });
    },
  });
};

export default registerBannerBoxMemberTrait;
