import registerBannerBoxMembersTrait from "./banner-box-members-trait";
import registerMateriaBoxMemberTrait from "./materia-box-members-trait";
import registerMenuMemberTrait from "./menu-members-trait";
import registerPickMateriaTrait from "./pick-materia-trait";
import registerYoutubeBoxMemberTrait from "./youtube-box-members-trait";
import registerDateFormatTrait from "./date-format-members-trait";

const registerTraits = (editor, initialConfig) => {
    registerPickMateriaTrait(editor, initialConfig);
    registerMateriaBoxMemberTrait(editor, initialConfig);
    registerBannerBoxMembersTrait(editor, initialConfig);
    registerYoutubeBoxMemberTrait(editor, initialConfig);
    registerMenuMemberTrait(editor, initialConfig);
    registerDateFormatTrait(editor, initialConfig);
};
export default registerTraits;
