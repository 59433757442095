export const RegisterDefaultWrapper = (editor) => {
  editor.Components.addType("wrapper", {
    model: {
      defaults: {
        tagName: "div", // use `div` instead of `body` in HTML export
      },

      // Skip wrapper in the HTML output
      toHTML(opts) {
        return this.getInnerHTML(opts);
      },
    },
  });
};
