import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
} from "react";

export const ActTranslate: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const refWrapper = useRef<HTMLElement>(null);
  const child = React.Children.only(children) as ReactElement;
  const clone = React.cloneElement(child, { ref: refWrapper });
  const haldeTranslate = (evt: React.MouseEvent<HTMLElement>) => {
    const language = (
      evt.target as HTMLElement
    ).dataset.translateLanguage?.toLowerCase();
  };
  useEffect(() => {
    if (refWrapper.current !== null) {
      var buttons = refWrapper.current.querySelectorAll(".btn-translate");
      buttons &&
        buttons.forEach((e) =>
          e.addEventListener("click", (e) =>
            haldeTranslate(e as unknown as React.MouseEvent<HTMLElement>)
          )
        );
    }
  }, []);

  return <>{clone}</>;
};

export default ActTranslate;
