import ActTranslate from "components/common/act-translate";
import { createId } from "grapesjs/src/utils/mixins";

const RegisterTranslate = (editor, config = null) => {
  editor.Components.addType("TranslatePortal", {
    extend: "react-component",
    model: {
      defaults: {
        component: ActTranslate,
        modulePath: "@components/common/act-translate",
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {},
        traits: [],
      },
      init: function (...args) {
        const newId = createId();

        const { id } = this.getAttributes();
        this.addAttributes({ id: id || this.getId() || newId });
      },
    },
    isComponent: (el) => el.tagName === "TRANSLATEPORTAL",
  });
};

export default RegisterTranslate;
