import { ApplicationPaths, LoginActions, LogoutActions } from 'constants/api-authorization'

import { Component } from 'react'
import { Container } from 'reactstrap'
import { Login } from './Login'
import { Logout } from './Logout'
import { Route } from 'react-router'

export default class ApiAuthorizationRoutes extends Component {
    render() {
        return (
            <Container>
                <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
                <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
                {/*<Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />*/}
                {/*<Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />*/}
                <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
                <Route
                    path={ApplicationPaths.LogOutCallback}
                    render={() => logoutAction(LogoutActions.LogoutCallback)}
                />
                <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
            </Container>
        )
    }
}

function loginAction(name) {
    return <Login action={name}></Login>
}

function logoutAction(name) {
    return <Logout action={name}></Logout>
}
