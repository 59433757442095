import React, { useCallback, useEffect, useState } from "react";
import { createId, timeSince } from "utils/mixins";
import { off, on } from "helpers/events";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import clock from "services/clock-service";
import { notificationCenterNotifyEvtName } from "constants/events-names";

const NotificationType = {
  success: "Success",
  error: "Danger",
  warning: "Warning",
  info: "Info",
};

export declare type NotificationProps = {
  id?: string;
  message: string;
  where?: string;
  type: "error" | "success" | "info" | "warning";
  when?: Date;
};

const ToastDialogGlobal = () => {
  const [notifications, setNotifications] = useState<Array<NotificationProps>>(
    []
  );

  const notifyFired = useCallback(
    (data: any) => {
      const { detail: notification } = data;

      setNotifications((prev) => [
        ...prev,
        {
          ...notification,
          id: createId(5),
          when: new Date(clock.currentUTCDate),
        },
      ]);
    },
    [setNotifications]
  );

  useEffect(() => {
    const id = setInterval(function () {
      setNotifications((prev) => [...prev]);
    }, 1000 * 5);
    return () => clearInterval(id);
  }, [setNotifications]);

  const handleClose = useCallback(
    (index: number) => {
      setNotifications((prev) => prev.filter((item, i) => i !== index));
    },
    [setNotifications]
  );

  useEffect(() => {
    on(notificationCenterNotifyEvtName, notifyFired);
    return () => {
      off(notificationCenterNotifyEvtName, notifyFired);
    };
  }, [notifyFired]);

  return (
    <ToastContainer
      position="top-end"
      style={{
        zIndex: 99999,
      }}
      className="position-fixed"
    >
      {notifications.map((notification, index) => (
        <Toast
          delay={5000}
          autohide={true}
          onClose={() => handleClose(index)}
          className="d-inline-block m-1"
          bg={NotificationType[notification.type].toLowerCase()}
          key={notification.id}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">
              {notification.where || "Atenção"}
            </strong>

            <small>{timeSince(notification.when)}</small>
          </Toast.Header>
          <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ToastDialogGlobal;
