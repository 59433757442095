import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { AppContextType } from '@/types/contexts/app-context'
import { LocalTenantType } from '@/types/tenant/tenant-local-type'
import clock from 'services/clock-service'
import tenantService from '../services/tenant-service'

const tenantServiceInstance = tenantService()
const AppContext = React.createContext<AppContextType>({} as AppContextType)
export const AppProvider = AppContext.Provider
export const AppConsumer = AppContext.Consumer

export const AppContextComponent: React.FC<PropsWithChildren> = ({ children }) => {
    //const [state, setState] = useState<AppContextType>({} as AppContextType)
    const [currentTenant, setCurrentTenant] = useState<LocalTenantType | null | undefined>(
        tenantServiceInstance.GetTenant()
    )

    const switchTenant = useCallback((tenantId: string) => tenantServiceInstance.SwitchTenant(tenantId), [])

    const switchTenantWithInstance = useCallback((newTenant: LocalTenantType) => {
        return tenantServiceInstance.Set(newTenant)
    }, [])

    const updateState = useCallback((newTenant: LocalTenantType | null) => {
        console.log('VAMOS ATUALIZAR O CONTEXT')
        setCurrentTenant(newTenant)
        // setState(prev => {
        //     return {
        //         currentTenant: newTenant,
        //         setCurrentTenant: switchTenantWithInstance,
        //         switchTenant: switchTenant,
        //         data: prev?.data || {},
        //         tenantId: newTenant?.id,
        //         layoutId: prev?.layoutId,
        //         clock
        //     }
        // })
    }, [])

    const watchTenantChanged = useCallback(
        (newTenant: LocalTenantType | null) => {
            console.log('TENANT WATCH IS FIRED!')
            updateState(newTenant)
        },
        [updateState]
    )

    useEffect(() => {
        console.log(`[APPCONTEXT]: GOING TRY START WATCH TENANT ${new Date()}`)
        //const tenant = tenantServiceInstance.GetTenant()

        /*
        Vide AppContextType to identify the required props:
        ---------------------------------------------------
        data: GroupedStaticPropsType<T>
        currentTenant?: LocalTenantType | null
        setCurrentTenant(newTenantInstance: LocalTenantType): void
        switchTenant(tenantId: string): void
        tenantId?: string
        layoutId?: string
        */
        //updateState(tenant)
        tenantServiceInstance.WatchChangeTenant(watchTenantChanged)

        return () => {
            console.log('[APPCONTEXT]: RUNNING CLEANNING UP FUNCTION OF WATCHING TENANT...')
            tenantServiceInstance.UnWatchChangeTenant(watchTenantChanged)
        }
    }, [watchTenantChanged])

    return (
        <AppContext.Provider
            value={{
                clock,
                currentTenant,
                tenantId: currentTenant?.id,
                setCurrentTenant: switchTenantWithInstance,
                switchTenant: switchTenant
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
