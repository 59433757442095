import { Button, InputGroup, InputGroupText, Input as ReactStrapInput } from 'reactstrap'
import { FormContext, UnformContext } from '@unform/core'
import React, { SyntheticEvent, useContext, useEffect, useRef } from 'react'

import ActFormItemLayout from './form-item-layout'
import { FormInputProps } from '@/types/form/input'
import { InputType } from 'reactstrap/es/Input'
import { SelectOptionModel } from '@/generated/portal-graphql-types'
import { Trash } from 'react-feather'
import { noop } from 'lodash'
import { useField } from '@unform/core'

export declare interface InputProps
    extends Omit<React.HTMLProps<HTMLInputElement>, 'size'>,
        Omit<FormInputProps, 'onChange'> {
    name: string
    prefix?: string
    sufix?: string
    usePrefixAndSufixAsValue?: boolean
    initialValue?: string
    type?: InputType
    itemType?: 'simple' | 'removable'
    removeAction?: (e: SyntheticEvent) => void
    formatter?: (value: string) => string
    dataOptions?: Array<SelectOptionModel>
    readOnly?: boolean
}

const Input: React.FC<InputProps> = ({
    name,
    initialValue,
    itemType,
    removeAction,
    prefix,
    sufix,
    usePrefixAndSufixAsValue,
    formatter = (value: string) => value,
    type = 'text',
    dataOptions,
    readOnly,
    label,
    required,
    description,
    helpText,
    size,
    onChange: externalOnChange,
    ...rest
}) => {
    const inputRef = useRef(null)
    const { unregisterField } = useContext<UnformContext>(FormContext)
    const { fieldName, registerField, defaultValue, error } = useField(name)
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            //path: "value",
            getValue(ref: any) {
                if (name === 'DatabaseEngine') console.log('DatabaseEngine', ref.innerRef)
                return (
                    ((usePrefixAndSufixAsValue && prefix) || '') +
                    ref.value +
                    ((usePrefixAndSufixAsValue && sufix) || '')
                )
            },
            setValue(ref: any, value: string) {
                if (fieldName.startsWith('HostNames')) {
                    console.log('fui chamado:value', value)
                    console.log('fui chamado:ref', ref)
                }

                ref.value = `${value !== null && value !== undefined ? value : ''}`
                    .replace((usePrefixAndSufixAsValue && prefix) || '', '')
                    .replace((usePrefixAndSufixAsValue && sufix) || '', '')
            },
            clearValue(ref: any) {
                //ref.setInputValue("");
                ref.value = ''
            }
        })
        return () => unregisterField(fieldName) // <= this really unregister the field
    }, [fieldName, registerField, unregisterField, name, usePrefixAndSufixAsValue, prefix, sufix])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        ;(externalOnChange || noop)(e)
    }

    return (
        <ActFormItemLayout {...{ name, label, required, description, helpText }}>
            <InputGroup className={error ? 'is-invalid' : ''}>
                {prefix && <InputGroupText>{prefix}</InputGroupText>}
                {type === 'select' ? (
                    <ReactStrapInput
                        disabled={readOnly}
                        innerRef={inputRef}
                        name={fieldName}
                        type="select"
                        //value={value}
                        onChange={handleChange}
                        className={error ? 'form-control is-invalid' : 'form-control'}
                    >
                        <option value={''}>Selecione</option>
                        {dataOptions &&
                            dataOptions.map((item, index) => (
                                <option key={item.key || `item_${index}`} value={item.value || ''}>
                                    {item.label}
                                </option>
                            ))}
                    </ReactStrapInput>
                ) : (
                    <ReactStrapInput
                        type={type}
                        className={error ? 'form-control is-invalid' : 'form-control'}
                        defaultValue={defaultValue}
                        innerRef={inputRef}
                        name={fieldName}
                        readOnly={readOnly}
                        disabled={readOnly}
                        {...(rest as any)}
                        //value={value}
                        onChange={handleChange}
                    />
                )}
                {sufix && <InputGroupText>{sufix}</InputGroupText>}
                {itemType && itemType === 'removable' && (
                    <Button color="danger" onClick={removeAction}>
                        <Trash size={14} />
                    </Button>
                )}
            </InputGroup>
        </ActFormItemLayout>
    )
}

export default Input
