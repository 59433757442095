import DmLocalLayout from "enums/dm-local-layout";
import Dummy from "components/next-dummy-component";
import { notificationCenterNotifyEvtName } from "constants/events-names";
import { trigger } from "helpers/events";

const Register = (editor) => {
    const { dmLocal } = editor.getConfig("storageManager");

    if (Number(dmLocal) === DmLocalLayout.Internal) {
        editor.Components.addType("InternalContent", {
            extend: "react-component",
            model: {
                defaults: {
                    component: Dummy,
                    modulePath: "next/head",
                    stylable: false,
                    resizable: true,
                    editable: true,
                    draggable: true,
                    droppable: false,
                },
            },
            isComponent: (el) => el.tagName === "INTERNALCONTENT",
        });

        editor.BlockManager.add("InternalContent", {
            label: "<div class='gjs-fonts gjs-f-b1'>Conteúdo Interno</div>",
            category: "React Components",
            content: `<InternalContent>Conteúdo Interno</InternalContent>`,
        });

        // helper function to control the number of internal component per document(only one is allowed)
        editor.on("component:add", (component) => {
            const tag = component.get("tagName");
            const internalComponentTag = "InternalContent";

            if (tag === internalComponentTag.toLowerCase()) {
                if (editor.getWrapper().findType(internalComponentTag).length > 1) {
                    component.remove();
                    console.log(
                        "REMOVED! Only one InternalContentComponent is allowed per document."
                    );

                    trigger(notificationCenterNotifyEvtName, {
                        message:
                            'Só é permitido um Componente "Conteúdo Interno" por página.',
                        type: "error",
                    });
                }
            }
        });
    }
};

export default Register;
