import { DocumentNode, gql } from '@apollo/client'

import client from 'services/graphql-service'

const LIST_PAGED = gql`
    query ListMateriasPaged($skip: Int, $take: Int) {
        materias(order: { NmTitulo: DESC }, skip: $skip, take: $take) {
            items {
                CdMateria
                NmTitulo
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
            }
            totalCount
        }
    }
`

const FILTER_BY_ID = gql`
    query ListWithPredicate($ids: LongOperationFilterInput) {
        materias(order: { NmTitulo: DESC }, where: { CdMateria: $ids }) {
            items {
                CdMateria
                NmTitulo
                DataAtualizacao
            }
        }
    }
`
const fetchData = async (query: DocumentNode, variables: any) => {
    const { data } = await client.query({
        query,
        variables
    })

    return {
        props: {
            data
        }
    }
}

export const GetMateriaByIdsAsync = async (ids: number[]) => {
    return await fetchData(FILTER_BY_ID, { ids })
}
declare type options = {
    ids: number[]
}
export const GetMateriaByIds2Async = async ({ ids }: options) => {
    return await fetchData(FILTER_BY_ID, { ids })
}

export const MateriaListPagedAsync = async ({ skip, take }: { skip: number; take: number }) => {
    return await fetchData(LIST_PAGED, { skip, take })
}

export default {
    MateriaListPagedAsync,
    GetMateriaByIdsAsync,
    GetMateriaByIds2Async
}
