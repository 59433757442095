//mport * as Yup from "yup";

import { DmPorteClienteEnum, DmTipoClienteEnum } from 'actcon-portal-types'

import Yup from 'extensions/yup-extended'

// var regex =
//   /^((https?:\/\/)|(www.)?)(?:([a-zA-Z]+)|(\d+\.\d+.\d+.\d+)):\d{0,5}$/;
export const schema = Yup.object().shape({
    TenantId: Yup.string(),
    ClientId: Yup.string()
        .required('O ID do Cliente é obrigatório.')
        .max(30, 'O Tamanho máximo do Identificador é 30 caracteres.')
        .ensure(),
    Name: Yup.string()
        .max(100, 'O Tamanho máximo do nome é 100 caracteres.')
        .required('O nome é obrigatório.')
        .ensure(),
    NamePersonInCharge: Yup.string()
        .nullable()
        .max(100, 'O Tamanho máximo do nome do responsável é 100 caracteres.')
        .transform(v => ([null, undefined, ''].indexOf(v?.trim()) > -1 ? null : v?.trim()))
        .default(null),
    TelephoneNumber: Yup.string().nullable().max(11, 'O Tamanho máximo do telefone é 11.').default(null),
    TelephoneExtensionNumber: Yup.string().nullable().max(4, 'O tamanho máximo do ramal é 4.').default(null),
    DatabaseEngine: Yup.string().when('IsSubSite', {
        is: (val?: boolean) => val === false,
        then: schema => schema.trim().required('O motor do banco de dados é obrigatório.').ensure(),
        otherwise: schema => schema.optional().nullable().default(null)
    }),
    UriBaseAdmin: Yup.string()
        .required('A URL base do Admin é obrigatória.')
        .url('O formato da URL é inválido.')
        //.matches(regex, "O formato da URL é inválido")
        .default(''),
    UriBaseAdminAlt: Yup.string().nullable().default(null).url('O formato da URL é inválido.'),
    // .matches(regex, {
    //   excludeEmptyString: true,
    //   message: "O formato da URL é inválido",
    // }),
    UriBasePublic: Yup.string()
        .required('A URL base do Público é obrigatória.')
        .url('O formato da URL é inválido.')
        //.matches(regex, "O formato da URL é inválido")
        .default(''),
    UriBasePublicAlt: Yup.string().nullable().default(null).url('O formato da URL é inválido.'),
    // .matches(regex, {
    //   excludeEmptyString: true,
    //   message: "O formato da URL é inválido",
    // }),
    CorsAllowedOrigins: Yup.array(
        Yup.string()
            .isHostName('Insira um host válida.')
            .emptyAsUndefined()
            .required('A URL é obrigatória.')
            //.url("Insira uma URL válida")
            .ensure()
    ).default([]),
    HostNames: Yup.array(
        Yup.string()
            .isHostName('Insira um host válida.')
            .emptyAsUndefined()
            .required('A URL é obrigatória.')
            //.url("Insira uma URL válida")
            .ensure()
    ).default([]),
    IsSubSite: Yup.boolean().required('Flag indicativa se é Intranet ou não é obrigatória.').default(false),
    IsAuthenticatedEnvironment: Yup.number()
        .typeError('Selecione se o login é obrigatório.')
        .required('É necessário informar se o login é obrigatório.')
        .min(0, 'Selecione se o login é obrigatório.')
        .default(-1),
    ParentTenantId: Yup.string().when('IsSubSite', {
        is: (val?: boolean) => val === true,
        then: schema => schema.required('É necessário informar o cliente pai.'),
        otherwise: schema => schema.optional()
    }),
    DmTipoCliente: Yup.mixed()
        .transform(x => parseInt(x, 10))
        .oneOf(
            [DmTipoClienteEnum.Camara, DmTipoClienteEnum.Prefeitura, DmTipoClienteEnum.Outro],
            'Você precisa selecionar uma das opções.'
        )
        .required(),
    CdUf: Yup.number().typeError(`Você precisa selecionar uma das opções.`).required(),
    CdMunicipio: Yup.number().typeError(`Você precisa selecionar uma das opções.`).required(),
    DmPorteMunicipio: Yup.mixed()
        .transform(x => parseInt(x, 10))
        .oneOf(
            [DmPorteClienteEnum.Pequeno, DmPorteClienteEnum.Medio, DmPorteClienteEnum.Grande],
            'Você precisa selecionar uma das opções.'
        )
        .required(),
    LoginType: Yup.string()
        .typeError('Selecione o tipo de Login.')
        .when('IsSubSite', {
            is: (val?: boolean) => val === true,
            then: schema => schema.required('É necessário informaro tipo de login do internauta.'),
            otherwise: schema => schema.required('É necessário informaro tipo de login do internauta.')
        }),
    UserRegisteringType: Yup.string()
        .typeError('Selecione o tipo de cadastro de Internauta.')
        .when('IsSubSite', {
            is: (val1?: boolean) => val1 === true,
            then: schema =>
                schema
                    .min(1, 'Selecione um tipo de registro.')
                    .required('É necessário informar o tipo de registro do internauta.'),
            otherwise: schema => schema.required().required('É necessário informar o tipo de registro do internauta.')
        }),
    ImageQuality: Yup.number()
        .typeError('A qualidade da imagem deve ser um número.')
        .required('A qualidade da imagem é obrigatória.')
        .integer('A qualidade da Imagem deve ser um valor inteiro.')
        .positive('A qualidade da Imagem deve ser um valor positivo.')
        .moreThan(0, 'A qualidade deve ser um valorentre 1-100.')
        .lessThan(101, 'A qualidade deve ser um valor entre 1-100.')
        .default(50),
    IsDraft: Yup.bool()
        .transform(v => !!v)
        .required()
})

export interface TenantSchema extends Yup.InferType<typeof schema> {}
