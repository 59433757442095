import 'bootstrap/dist/css/bootstrap.css'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

console.warn(
    `⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ CAUTION ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ : WE ARE RUNING IN ${process.env.REACT_APP_ENVIRONMENT_NAME} MODE!`
)

//* REMOVE TEMPORALLY THE WARNINGS RELATES WITH DEPRECATED defaultProps
const error = console.error
console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return
    error(...args)
}

root.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
