import { Fragment, useEffect, useState } from 'react'

import { LocalTenantType } from 'types/tenant/tenant-local-type'
import { SecureHttpClient } from '../../services/http-client-service'
import tenantService from '../../services/tenant-service'
import { tenantsList } from 'constants/api-routes'

// declare type TenantSelectedOption = {
//   name: string;
//   id: string;
//   is_sub_tenant: boolean;
// };

declare type TenantSelectOptions = {
    handleChange: (selected: LocalTenantType) => void
    value: string
}

declare type TenantConfig = {
    Name: string
    TenantId: string
    IsSubSite: boolean
    ParentTenantId: string
    InCreation: boolean
    StepsCompleted: number[]
    Step1TasksCompleted: number[]
}

declare type TenantConfigGroups = {
    parent: TenantConfig
    children: TenantConfig[]
}

const sortBy = (propName: string) => (a: any, b: any) =>
    a[propName] > b[propName] ? 1 : b[propName] > a[propName] ? -1 : 0

const TenantSelect: React.FC<TenantSelectOptions> = ({ handleChange, value }) => {
    const [tenants, setTenants] = useState<TenantConfigGroups[]>([])
    const [isBusy, setIsBusy] = useState(true)
    const tenantServiceInstance = tenantService()

    useEffect(() => {
        const fetchTenantsAsync = async () => {
            try {
                const data = (await SecureHttpClient.Get(tenantsList)) as TenantConfig[]
                const parents = data.filter(item => !item.IsSubSite).sort(sortBy('Name'))
                const groups = parents.map(parent => ({
                    parent,
                    children: data.filter(c => c.ParentTenantId === parent.TenantId).sort(sortBy('Name'))
                }))
                setTenants(groups)
            } catch (ex) {
                console.warn('Ooops. Something went wrong =/', ex)
            } finally {
                setIsBusy(false)
            }
        }
        fetchTenantsAsync()
    }, [setTenants, setIsBusy])

    return (
        <select
            value={value || ''}
            className="form-select"
            aria-label="Default select"
            disabled={isBusy}
            onChange={({ target: select }) =>
                (handleChange || function () {})(
                    (select.selectedIndex > 0 /*&& stored?.id !== select.value.trim()*/
                        ? tenantServiceInstance.MapFromServerToLocal(
                              JSON.parse(select.options[select.selectedIndex].getAttribute('data-tenant')!)
                          )
                        : null) as LocalTenantType
                )
            }
        >
            <option value="">Selecione</option>
            {tenants.map(({ parent, children }, parentIndex) => (
                <Fragment key={parent.TenantId}>
                    <option value={parent.TenantId} data-tenant={JSON.stringify(parent)}>
                        {`${parentIndex + 1} - ${parent.Name}`}
                    </option>
                    {children.map((child, childIndex) => (
                        <option key={child.TenantId} value={child.TenantId} data-tenant={JSON.stringify(child)}>
                            {`\u00A0\u00A0${parentIndex + 1}.${childIndex + 1} -${child.Name}`}
                        </option>
                    ))}
                </Fragment>
            ))}
        </select>
    )
}
export default TenantSelect
