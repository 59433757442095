import React, { PropsWithChildren } from "react";

import GrapesJS from "grapesjs";
import { storageKeysPrefix } from "./presets/consts";
import { storageRemote } from "lib/grapesjs/presets/consts";

export interface GrapesjsReactProps {
    id: HTMLElement["id"];
    documentId: string;
    dmLocal: number;
    onInit?(editor?: GrapesJS.Editor): void;
    plugins?: Array<any>;
    pluginsOpts?: Record<string, any>;
    optsHtml?: Record<string, any>;
    onDestroy?(): void;
    autorender?: boolean;
    i18n?: Record<string, any>;
}

export function GrapesjsReact(
    props: PropsWithChildren<GrapesjsReactProps> &
        Parameters<typeof GrapesJS.init>[0]
) {
    const { id, onInit, onDestroy, documentId, dmLocal, children, ...options } =
        props;

    const [editor, setEditor] = React.useState<GrapesJS.Editor>();

    React.useEffect(() => {
        const selector = `#${id}`;
        if (!editor) {
            const instance = GrapesJS.init({
                container: selector,
                fromElement: !!children,
                storageManager: {
                    id: storageKeysPrefix, // Prefix identifier that will be used on parameters
                    type: storageRemote, // Type of the storage
                    autosave: false, // Store data automatically
                    autoload: true, // Auto load stored data on init
                    stepsBeforeSave: 1, // If auto save enabled, indicates how many changes are necessary before store method is triggered
                    documentId: documentId || null, // the id of layout that is being edited
                    dmLocal, // the additional value to distinguish where will be used this layout (ex. Default, Interno)
                },
                ...options,
                jsInHtml: false, // ignore the JS component scripts to be exported from editor.getHtml() with HTML
            });
            setEditor(instance);

            if (typeof onInit === "function") {
                onInit(instance);
            }
        }
    }, [children, editor, id, onInit, options, documentId]);

    React.useEffect(() => {
        return function cleanup() {
            if (editor) {
                if (typeof onDestroy === "function") {
                    onDestroy();
                }
                const index: number = GrapesJS.editors.findIndex(
                    (e) => e.Config.container === editor.Config.container
                );
                if (typeof index === "number" && index >= 0) {
                    GrapesJS.editors.splice(index, 1);
                }
                editor.destroy();
            }
        };
    }, [editor, onDestroy]);

    return <div id={id}>{children}</div>;
}

export default GrapesjsReact;
