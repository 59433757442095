import {
    countTraint,
    defaultSizeYoutubeItems,
    idsCategoriaTrait,
    idsTrait,
} from "./defaults";

import YouTubeBox from "components/boxes/youtube-box";
import { createId } from "grapesjs/src/utils/mixins";
import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";

const RegisterYouTubeBox = (editor, config = null) => {
    editor.Components.addType("YouTubeBox", {
        extend: "react-component",
        model: {
            defaults: {
                component: YouTubeBox,
                modulePath: "@components/boxes/youtube",
                fetcher: {
                    path: "@/providers/video-youtube-server-data-provider",
                    moduleName: "VideoYouTubeDataProvider",
                    method: "GetYouTubeListAsync",
                },
                stylable: true,
                resizable: true,
                editable: true,
                draggable: true,
                droppable: true,
                attributes: {
                    "display-mode": "list-static",
                    "origin-data": "latest",
                    members: "T,D,I",
                },
                traits: [
                    {
                        type: "select", // Type of the trait
                        label: "Modo de exibição", // The label you will see in Settings
                        name: "display-mode", // The name of the attribute/property to use on component
                        options: [
                            { id: "slider", name: "Slider" },
                            { id: "list-static", name: "Lista estática" },
                        ],
                    },
                    {
                        type: "select", // Type of the trait
                        label: "Origem dados", // The label you will see in Settings
                        name: "origin-data", // The name of the attribute/property to use on component
                        options: [
                            { id: "latest", name: "Útimas publicações" },
                            { id: "random", name: "Aleatória" },
                            { id: "category", name: "Últimas da categoria" },
                            { id: "specific", name: "Específica(s)" },
                        ],
                    },
                    {
                        type: customTraitsIds.YoutubeBoxMembers,
                        label: "Membros",
                    },
                ],
            },
            init: function (...args) {
                const newId = createId();
                // console.log("createId()", newId);
                // console.log("this.getId()", this.getId());
                // console.log(
                //   "this.attributes.attributes.id",
                //   this?.attributes?.attributes?.id
                // );
                // console.log("this", this);

                const { "origin-data": originData, size, id } = this.getAttributes();
                this.addAttributes({ id: id || this.getId() || newId });

                switch (originData?.toLowerCase()) {
                    case "specific":
                        this.removeTrait(countTraint.name);
                        this.addTrait(idsTrait);
                        break;
                    default:
                        this.removeTrait(idsTrait.name);
                        this.addAttributes({
                            size: size || defaultSizeYoutubeItems,
                        });
                        this.addTrait(countTraint);
                        break;
                }
                this.on("change:attributes:origin-data", this.handleTypeChange);
            },
            handleTypeChange() {
                const originData = this.getAttributes()["origin-data"];
                const comp = editor.getSelected();
                switch (originData?.toLowerCase()) {
                    case "specific":
                        comp.removeAttributes([countTraint.name, idsCategoriaTrait.name]);
                        comp.addAttributes({ [idsTrait.name]: "" });
                        this.removeTrait(countTraint.id);
                        this.removeTrait(idsCategoriaTrait.id);
                        this.addTrait(idsTrait);
                        break;
                    default:
                        comp.removeAttributes([idsTrait.name]);
                        comp.addAttributes({ [countTraint.name]: defaultSizeYoutubeItems });
                        this.removeTrait(idsTrait.id);
                        this.addTrait(countTraint);
                        if (originData.toLowerCase() === "category") {
                            comp.addAttributes({ [idsCategoriaTrait.name]: "" });
                            this.addTrait(idsCategoriaTrait);
                        } else {
                            this.removeTrait(idsCategoriaTrait.id);
                            comp.removeAttributes([idsCategoriaTrait.name]);
                        }
                        break;
                }
            },
        },
        isComponent: (el) => el.tagName === "YOUTUBEBOX",
    });

    editor.BlockManager.add("YouTubeBox", {
        label: "<div class='gjs-fonts gjs-f-b1'>YouTube Box</div>",
        category: "React Components",
        content: `<YouTubeBox />`,
    });
};

export default RegisterYouTubeBox;
