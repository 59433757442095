import Local from "./local";
import Remote from "./remote";
import LocalRemote from "./local-remote";

const RegisterStorages = (editor, config) => {
  console.warn("Registering storages ...");
  Local(editor, config);
  Remote(editor, config);
  LocalRemote(editor, config);
};

export default RegisterStorages;
