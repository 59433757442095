import { Modal, ModalBody } from 'reactstrap'

declare type props = {
    isLoading: boolean
    message?: string
}

const Loading: React.FC<props> = ({ isLoading, message }) => {
    return (
        <Modal isOpen={isLoading} backdrop="static" size="sm" centered={true} scrollable={false}>
            <ModalBody>
                <div className="loader">
                    <div className="text-center ">
                        {/* <Spinner /> */}
                        Aguarde
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Loading
