import Dummy from "components/next-dummy-component";

const Register = (editor) => {
    editor.Components.addType("NextImage", {
        extend: "react-component",
        model: {
            defaults: {
                component: Dummy,
                modulePath: "next/image",
                stylable: true,
                resizable: true,
                editable: true,
                draggable: true,
                droppable: true,
            },
        },
        isComponent: (el) => el.tagName === "NEXTIMAGE",
    });
};

export default Register;
