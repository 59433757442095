import "./NavMenu.css";

import {
  Collapse,
  Container,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import { PropsWithChildren, useCallback, useContext, useState } from "react";
import {
  layoutBuilderListRouter,
  tenantInsertUpdateRoute,
} from "constants/app-routes";

import AppContext from "../contexts/app-context";
import { Link } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import { parseUrlTemplate } from "utils/strings-utils";

const NavMenu: React.FC<PropsWithChildren> = (props) => {
  const { currentTenant } = useContext(AppContext);
  const [state, setState] = useState({
    collapsed: true,
  });

  const toggleNavbar = useCallback(() => {
    setState((prev) => ({
      collapsed: !prev.collapsed,
    }));
  }, [setState]);

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 sticky-top"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            {currentTenant?.name || "Portal Fácil - BackOffice"}
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!state.collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/counter">
                  Counter
                </NavLink>
              </NavItem>
              <NavItem>
                {currentTenant && (
                  <NavLink
                    tag={Link}
                    className="text-dark"
                    to={layoutBuilderListRouter}
                  >
                    Layouts
                  </NavLink>
                )}
              </NavItem>
              {/*<NavItem>*/}
              {/*    <NavLink*/}
              {/*        tag={Link}*/}
              {/*        className="text-dark"*/}
              {/*                  to={menuBuilderListRouter}*/}
              {/*    >*/}
              {/*        Menus*/}
              {/*    </NavLink>*/}
              {/*</NavItem>*/}
              {currentTenant && (
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark"
                    to={parseUrlTemplate(tenantInsertUpdateRoute, {
                      action: "edit",
                      tenantId: currentTenant?.id,
                    })}
                  >
                    Cliente
                  </NavLink>
                </NavItem>
              )}

              <LoginMenu />
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
