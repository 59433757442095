import React, { useCallback, useEffect, useState } from 'react'
import { off, on } from 'helpers/events'

import Button from 'react-bootstrap/Button'
import { LoginActions } from 'constants/api-authorization'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { ReactComponent as SwitchTenantIcon } from '../assets/switch-tenant.svg'
import TenantSelect from './tenant/tenant-select'
import authService from 'services/authorize-service'
import { selectTenantModalOpen } from 'constants/events-names'
import useCurrentTenant from '../hooks/use-current-tenant'
import { useHistory } from 'react-router'

const SwitchTenant = () => {
    const history = useHistory()

    const { current: stored, switchTenant: commit } = useCurrentTenant()
    const [currentPreSelectedTenant, setPreselectedTenant] = React.useState(stored || null)

    const [isOpen, setIsOpen] = React.useState(false)

    const [isValidTenant, setIsValidTenant] = React.useState(false)
    const [isProcessing, setIsProcessing] = React.useState(false)

    const handleClose = () => setIsOpen(false)
    const handleShow = async () => {
        const isAuthenticated = await authService.isAuthenticated()
        if (!isAuthenticated) {
            useHistory.push(LoginActions.Login)
        } else {
            setIsOpen(true)
            setPreselectedTenant(stored || null)
        }
    }

    const [showConfirmation, setShowConfirmation] = useState(false)

    const handleCloseConfirmation = () => setShowConfirmation(false)
    const handleShowConfirmation = () => setShowConfirmation(true)
    const handleTenantChange = tenant => {
        setPreselectedTenant(tenant)
        const isValid =
            tenant?.id !== undefined && tenant?.id !== null && tenant?.id !== '' && tenant?.id !== stored?.id
        setIsValidTenant(isValid)
    }

    const handleSwitchTenant = (e, confirm) => {
        if (confirm === undefined) {
            handleClose()
            handleShowConfirmation()
        } else {
            if (confirm) {
                setIsProcessing(true)
                window.setTimeout(() => {
                    handleCloseConfirmation()
                    setIsProcessing(false)
                    history.push('/')
                    commit(currentPreSelectedTenant)
                }, 500)
            } else {
                handleClose()
                setIsValidTenant(true)
            }
        }
    }

    const openModalSelectTenantRequest = useCallback(() => setIsOpen(true), [setIsOpen])

    useEffect(() => {
        on(selectTenantModalOpen, openModalSelectTenantRequest)
        return () => {
            off(selectTenantModalOpen, openModalSelectTenantRequest)
        }
    }, [openModalSelectTenantRequest])

    return (
        <>
            <SwitchTenantIcon width={50} onClick={handleShow} />

            <Modal
                show={showConfirmation}
                onHide={handleCloseConfirmation}
                backdrop="static"
                size="sm"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <Modal.Header>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Você confirma a alternância de cliente? Esta operação irá recarregar totalmente a página.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowConfirmation(false)} disabled={isProcessing}>
                        Não
                    </Button>
                    <Button variant="primary" onClick={e => handleSwitchTenant(e, true)} disabled={isProcessing}>
                        {isProcessing && (
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        )}{' '}
                        {isProcessing ? 'Alternando...' : 'Sim'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isOpen} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h2>Selecione</h2>
                            <TenantSelect handleChange={handleTenantChange} value={currentPreSelectedTenant?.id} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSwitchTenant} disabled={!isValidTenant}>
                        Mudar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SwitchTenant
