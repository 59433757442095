import './custom.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-notifications-component/dist/theme.css'

import {
    layoutBuilderInsertUpdateRouter,
    layoutBuilderListRouter,
    menuBuilderInsertUpdateRouter,
    menuBuilderListRouter,
    tenantInsertUpdateRoute
} from 'constants/app-routes'

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { AppContextComponent } from './contexts/app-context'
import { ApplicationPaths } from './constants/api-authorization'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ConfirmDialogGlobal from 'components/dialog/confirm-dialog-global'
import Counter from './components/Counter'
import { FetchData } from './components/FetchData'
import { Home } from './components/Home'
import { Layout } from './components/Layout'
import LayoutBuilder from './pages/layout-builder-page'
import LayoutBuilderList from './pages/layout-builder-list-page'
import MenuBuilder from './pages/menu-builder-page'
import MenuBuilderList from './pages/menu-builder-list-page'
import { ReactNotifications } from 'react-notifications-component'
import { Route } from 'react-router'
import SelectTenantPage from './pages/select-tenant-page'
import TenantCreationForm from './pages/tenant-creation/index'
import TenantCreationHub from './components/hubs/tenant-creation-hub'
import ToastGlobal from 'components/dialog/toast-dialog-global'

const App = () => {
    return (
        <AppContextComponent>
            <TenantCreationHub>
                <ConfirmDialogGlobal />
                <ReactNotifications />
                <ToastGlobal />
                <Layout>
                    <AuthorizeRoute exact path="/" component={Home} />
                    <AuthorizeRoute path="/counter" component={Counter} />
                    <AuthorizeRoute path={ApplicationPaths.SelectTenant} component={SelectTenantPage} />
                    <AuthorizeRoute path="/fetch-data" component={FetchData} />
                    <AuthorizeRoute path={layoutBuilderInsertUpdateRouter} component={LayoutBuilder} />
                    <AuthorizeRoute path={layoutBuilderListRouter} component={LayoutBuilderList} />
                    <AuthorizeRoute path={tenantInsertUpdateRoute} component={TenantCreationForm} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <AuthorizeRoute path={menuBuilderInsertUpdateRouter} component={MenuBuilder} />
                    <AuthorizeRoute path={menuBuilderListRouter} component={MenuBuilderList} />
                </Layout>
            </TenantCreationHub>
        </AppContextComponent>
    )
}

export default App
