import { MapObjToArray } from "../utils/object-utils";
import React from "react";

export default function ListComponentProperties({
  name = "unknown",
  ...rest
}: any) {
  const arr = MapObjToArray(rest);
  return (
    <>
      <div>Hello from {name}</div>
      <ul>
        {arr.map((prop) => (
          <li key={prop.key}>
            <strong>{prop.key} </strong>
            <span>{prop.value}</span>
          </li>
        ))}
      </ul>
    </>
  );
}
