import 'grapesjs/dist/css/grapes.min.css'

import React, { useState } from 'react'
import { initializingLayout, savingChangesInLayout } from 'constants/messages'
import { useHistory, useParams } from 'react-router-dom'

import BaseReactComponent from '../lib/grapesjs/types/base-react-component'
import { EditorWithOff } from 'lib/grapesjs/presets/types/editor-with-off'
import GrapesJs from 'grapesjs'
import { GrapesjsReact } from '../lib/grapesjs'
import { Helmet } from 'react-helmet'
import Loading from 'components/Loading'
import MessageDialog from 'components/dialog/message-dialog'
import ReactComponents from '../lib/grapesjs/types/react-components'
import { RegisterDefaultWrapper } from '../lib/grapesjs/types/wrapper-default'
import RouteAction from 'enums/route-action'
import { cmdSelectStarterTemplate } from 'lib/grapesjs/presets/consts'
import grapesBoostrap from 'lib/grapesjs/bootstrap/source'
import { layoutBuilderInsertUpdateRouter } from 'constants/app-routes'
import { parseUrlTemplate } from 'utils/strings-utils'

const LayoutBuilderPage = () => {
    const { documentId, action, dmLocal } = useParams<any>()
    //const history = useHistory();

    const [isLoading, setIsLoading] = useState(true)
    const [layoutId, setLayoutId] = useState(documentId)

    const [isOpenMessageDialog, setIsOpenMessageDialog] = useState(false)
    const [messageDialog] = useState('Layout salvo com sucesso!')

    const handleMessageDialog = React.useCallback(
        () =>
            new Promise<void>((resolve, reject) => {
                if (action === RouteAction.new) {
                    window.location.replace(
                        parseUrlTemplate(layoutBuilderInsertUpdateRouter, {
                            action: RouteAction.edit,
                            dmLocal: dmLocal,
                            documentId: layoutId
                        })
                    )
                }

                setIsOpenMessageDialog(false)
                resolve()
            }),
        [action, dmLocal, layoutId]
    )

    const [loadingMessage, setLoadingMessage] = useState<string | undefined>(initializingLayout)

    function beforeExporTemplate(opts: any) {
        console.log('Before the command run ...')
        if (0 /* some condition */) {
            opts.abort = 1
        }
    }

    function afterExportTemplate() {
        console.log('After the command run')
    }

    function abortExportTemplate() {
        console.log('Command aborted')
    }

    function saving() {
        console.warn('storage:start:store')
        setLoadingMessage(savingChangesInLayout)
        setIsLoading(true)
    }

    function saved(layoutId: any) {
        setTimeout(() => {
            console.warn('storage:end:store')
            setLayoutId(layoutId)
            setLoadingMessage(undefined)
            setIsLoading(false)
            setIsOpenMessageDialog(true)
        }, 500)
    }

    function loaded(editor: EditorWithOff) {
        console.warn('Editor was loaded...')
        setLoadingMessage(undefined)
        setIsLoading(false)
        if (action === RouteAction.new) {
            editor.runCommand(cmdSelectStarterTemplate, { dmLocal: dmLocal })
        }
    }

    const init = (editor: EditorWithOff) => {
        // clean any previously bounded event
        editor.off('run:export-template:before', beforeExporTemplate)
        editor.off('run:export-template', afterExportTemplate)
        editor.off('abort:export-template', abortExportTemplate)
        editor.off('load', () => loaded(editor))

        editor.off('storage:start:store', saving)
        editor.off('storage:end:store', saved)

        // bind the events
        editor.on('run:export-template:before', beforeExporTemplate)
        editor.on('run:export-template', afterExportTemplate)
        editor.on('abort:export-template', abortExportTemplate)
        editor.on('load', () => loaded(editor))

        editor.on('storage:start:store', saving)
        editor.on('storage:end:store', saved)
        editor.render()
    }
    return (
        <>
            <Helmet>
                <script src="https://unpkg.com/prettier@1.13.0/standalone.js"></script>
                <script src="https://unpkg.com/prettier@1.13.0/parser-babylon.js"></script>
                <script src="https://unpkg.com/prettier@1.13.0/parser-postcss.js"></script>
            </Helmet>
            <MessageDialog isOpen={isOpenMessageDialog} message={messageDialog} handleButton={handleMessageDialog} />
            <Loading isLoading={isLoading} message={loadingMessage} />
            <GrapesjsReact
                id="grapesjs-react"
                plugins={[
                    'presets-plugin',
                    BaseReactComponent,
                    ReactComponents,
                    RegisterDefaultWrapper,
                    grapesBoostrap
                ]}
                pluginsOpts={{
                    'presets-plugin': {
                        modalImportContent: function (editor: GrapesJs.Editor) {
                            return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
                        },
                        modalImportTitle: 'Importar Código',
                        modalImportButton: 'Importar',
                        modalExportTitle: 'Pré-visualização/Exportar Código',
                        modalExportButton: 'Exportar'
                    },
                    'grapesjs-blocks-bootstrap5': {
                        blocks: {
                            // ...
                        },
                        blockCategories: {
                            // ...
                        },
                        labels: {
                            // ...
                        }
                        // ...
                    },
                    textCleanCanvas: 'Confirma a limpeza de todo conteúdo?'
                }}
                onInit={init}
                documentId={documentId}
                dmLocal={dmLocal}
                optsHtml={{ cleanId: false }}
                autorender={false}
                i18n={{
                    locale: 'pt', // default locale
                    detectLocale: false, // by default, the editor will detect the language
                    localeFallback: 'en', // default fallback
                    //messages: { it, tr },
                    messages: {
                        pt: { msg1: 'Olá' }
                    }
                }}
                canvas={{
                    styles: [
                        'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css',
                        'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css',
                        'https://localhost:7044/styles/layout-builder-canvas.css'
                    ],
                    scripts: ['https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js']
                }}
            />
        </>
    )
}
export default LayoutBuilderPage
