import Dummy from "components/next-dummy-component";

const Register = (editor) => {
  editor.Components.addType("NexScript", {
    extend: "react-component",
    model: {
      defaults: {
        component: Dummy,
        modulePath: "next/script",
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
      },
    },
    isComponent: (el) => el.tagName === "NEXTSCRIPT",
  });
};

export default Register;
