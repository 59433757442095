class TenantCreationStepsAndTasks {
    // Steps
    public static TenantCreationStep1 = 1

    // Common Tasks
    public static FillBasicInfo = 0

    // Tasks of Step 1
    public static CreateDatabaseTaskId = 1
    public static CreateUserDatabaseTaskId = 2
    public static DataSeedTaskId = 3
    public static RequestRegisterDomainTaskId = 4
    public static CheckRegisterDomainStatusTaskId = 5
    public static RequestRegisterOAuthSettingsTaskId = 6
    public static CheckRegisterOAuthSettingsStatusTaskId = 7

    // Tasks composition - Traditional tenant
    public static TasksOfStep1 = [
        this.FillBasicInfo,
        this.CreateDatabaseTaskId,
        this.CreateUserDatabaseTaskId,
        this.DataSeedTaskId,
        this.RequestRegisterOAuthSettingsTaskId,
        this.CheckRegisterOAuthSettingsStatusTaskId,
        this.RequestRegisterDomainTaskId,
        this.CheckRegisterDomainStatusTaskId
    ]

    // Tasks composition - Subtenant
    public static TasksOfStep1SubTenant = [
        this.FillBasicInfo,
        this.RequestRegisterOAuthSettingsTaskId,
        this.CheckRegisterOAuthSettingsStatusTaskId,
        this.RequestRegisterDomainTaskId,
        this.CheckRegisterDomainStatusTaskId
    ]

    public static GetNextTaskId(step: number, taskCompleted: number[], isSubSite: boolean, isInsert: boolean) {
        console.warn('taskCompleted', taskCompleted)
        switch (step) {
            case this.TenantCreationStep1:
                var lastTaskCompleted =
                    taskCompleted && taskCompleted?.length > 0
                        ? (taskCompleted || []).slice(-1)[0]
                        : !isInsert
                          ? 0
                          : undefined
                if (lastTaskCompleted === undefined) return TenantCreationStepsAndTasks.FillBasicInfo

                if (lastTaskCompleted === TenantCreationStepsAndTasks.FillBasicInfo) {
                    if (isSubSite) return TenantCreationStepsAndTasks.RequestRegisterDomainTaskId
                    else return TenantCreationStepsAndTasks.CreateDatabaseTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.CreateDatabaseTaskId) {
                    return TenantCreationStepsAndTasks.CreateUserDatabaseTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.CreateUserDatabaseTaskId) {
                    return TenantCreationStepsAndTasks.DataSeedTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.DataSeedTaskId) {
                    return TenantCreationStepsAndTasks.RequestRegisterDomainTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.RequestRegisterDomainTaskId) {
                    return TenantCreationStepsAndTasks.CheckRegisterDomainStatusTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.CheckRegisterDomainStatusTaskId) {
                    return TenantCreationStepsAndTasks.RequestRegisterOAuthSettingsTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.RequestRegisterOAuthSettingsTaskId) {
                    return TenantCreationStepsAndTasks.CheckRegisterOAuthSettingsStatusTaskId
                } else if (lastTaskCompleted === TenantCreationStepsAndTasks.CheckRegisterOAuthSettingsStatusTaskId) {
                    return null
                } else {
                    throw new Error('TASK_NOT_RECOGNIZED')
                }
            default:
                throw new Error('STEP_NOT_RECOGNIZED')
        }
    }
}

export default TenantCreationStepsAndTasks
