import { customTraitsIds } from "lib/grapesjs/presets/traits/constants";

function prev(el, selector) {
    if (selector) {
        let previous = el.previousElementSibling;
        while (previous && !previous.matches(selector)) {
            previous = previous.previousElementSibling;
        }
        return previous;
    } else {
        return el.previousElementSibling;
    }
}

const titleMember = ({ isChecked, disableUp = false, disableDown = false }) => `
<div class="row">
    <div class="col-sm-8">
        <label for="title">
            <input type="checkbox" value="T" name="title" ${(isChecked && "checked") || ""
    } />
            <span>3.1 - Título</span>
        </label>
    </div>
    <div class="col-sm-4">
        <button class="btn-move-member btn-up" data-action="up" ${(disableUp && "disabled") || false
    }><i class="bi bi-arrow-up d-block"></i></button>
        <button class="btn-move-member btn-down" data-action="down" ${(disableDown && "disabled") || false
    }><i class="bi bi-arrow-down d-block"></i></button>
    </div>
</div>`;

const headLineMember = ({
    isChecked,
    disableUp = false,
    disableDown = false,
}) => `
<div class="row">
<div class="col-sm-8">
    <label for="headline">
        <input type="checkbox" value="H" name="headline" ${(isChecked && "checked") || ""
    } />
        <span>3.2 - Chamada</span>
    </label>
</div>
<div class="col-sm-4">
    <button class="btn-move-member btn-up" data-action="up" ${(disableUp && "disabled") || false
    }><i class="bi bi-arrow-up d-block"></i></button>
    <button class="btn-move-member btn-down" data-action="down" ${(disableDown && "disabled") || false
    }><i class="bi bi-arrow-down d-block"></i></button>
</div>
</div>
`;

const dateMember = ({ isChecked, disableUp = false, disableDown = false }) => `
<div class="row">
    <div class="col-sm-8">
        <label for="date">
            <input type="checkbox" value="D" name="date" ${(isChecked && "checked") || ""
    } />
            <span>3.3 - Data</span>
        </label>
    </div>
    <div class="col-sm-4">
        <button class="btn-move-member btn-up" data-action="up" ${(disableUp && "disabled") || false
    }><i class="bi bi-arrow-up d-block"></i></button>
        <button class="btn-move-member btn-down" data-action="down" ${(disableDown && "disabled") || false
    }><i class="bi bi-arrow-down d-block"></i></button>
    </div>
</div>
`;

const imageMember = ({
    isChecked,
    isCheckedLegend,
    disableUp = false,
    disableDown,
}) => `
<div class="row">
<div class="col-sm-8">
    <label for="image">
        <input type="checkbox" value="I" name="image" ${(isChecked && "checked") || ""
    } />
        <span>3.4 - Imagem</span>
    </label>
    <div name="div-legend-of-image" style="display: ${(isChecked && "block") || "none"
    };">
        <label for="legend">
        <input type="checkbox" value="L" name="legend" ${(isCheckedLegend && "checked") || ""
    } />
        <span>3.5 - Legenda da imagem</span>
        </label> 
    </div>
</div>
<div class="col-sm-4">
    <button class="btn-move-member btn-up" data-action="up" ${(disableUp && "disabled") || false
    }><i class="bi bi-arrow-up d-block"></i></button>
    <button class="btn-move-member btn-down" data-action="down" ${(disableDown && "disabled") || false
    }><i class="bi bi-arrow-down d-block"></i></button>
</div>
</div>
`;

const registerMateriaBoxMemberTrait = (editor, initialConfig) => {
    editor.TraitManager.addType(customTraitsIds.MateriaBoxMembers, {
        // Completely remove the wrapper
        templateInput: "",

        createLabel({ label }) {
            return `<div>
          <div>Before</div>
          ${label}
          <div>After</div>
        </div>`;
        },
        // Expects as return a simple HTML string or an HTML element
        createInput({ trait }) {
            const that = this;
            const members = (editor.getSelected().getAttributes()["members"] || "")
                .toUpperCase()
                .replace(/\s/g, "")
                .split(",")
                .filter((x) => x && x?.trim() !== "");

            const membersFilled = [
                ...members,
                ...["T", "H", "D", "I"].filter((x) => !members.includes(x)),
            ];

            const availableTraits = [
                {
                    key: "T", // title
                    template: titleMember,
                },
                {
                    key: "H", // headline (a.k.a chamada)
                    template: headLineMember,
                },
                {
                    key: "D", // date
                    template: dateMember,
                },
                {
                    key: "I", // image
                    template: imageMember,
                },
            ];

            let html = ` 
                <div class="materia-members-trait">
                    <div>3 &#45; Membros</div>
                    <div class="materia-members-traint-items">
                    ${availableTraits
                    .sort(
                        (a, b) =>
                            membersFilled.indexOf(a.key) -
                            membersFilled.indexOf(b.key)
                    )
                    .map((x, i, arr) =>
                        x.template({
                            isChecked: members.includes(x.key),
                            isCheckedLegend: members.includes("L"),
                            disableDown: i === arr.length - 1,
                            disableUp: i === 0,
                        })
                    )
                    .join("")}
                    </div>
                </div>
            `;

            const el = document.createElement("fieldset");
            el.innerHTML = html;
            el.querySelectorAll(".btn-move-member").forEach((e) => {
                console.log("element", e);
                e.addEventListener(
                    "click",
                    (function (element) {
                        return (evt) => {
                            evt.preventDefault();
                            const currentMember = element.parentElement.parentElement;
                            const container = currentMember.parentElement;
                            const action = element.getAttribute("data-action");
                            const lastPos = container.children.length - 1;

                            // The equivalent of parent.children.indexOf(child)
                            var index = Array.prototype.indexOf.call(
                                container.children,
                                currentMember
                            );

                            const factor = action === "down" ? 1 : -1;
                            const newPos = index + factor * 1;

                            if (newPos < 0 || newPos > lastPos) return; //invalid operations

                            if (action === "down")
                                container.insertBefore(
                                    action === "down"
                                        ? currentMember.nextElementSibling
                                        : currentMember.previousElementSibling,
                                    currentMember
                                );
                            else
                                container.insertBefore(
                                    currentMember,
                                    action === "down"
                                        ? currentMember.nextElementSibling
                                        : currentMember.previousElementSibling
                                );

                            const first = Array.from(
                                container.querySelectorAll(".btn-up")
                            )[0];
                            const last = Array.from(
                                container.querySelectorAll(".btn-down")
                            ).slice(-1)[0];

                            container
                                .querySelectorAll(".btn-move-member")
                                .forEach((x) =>
                                    x === first || x === last
                                        ? x.setAttribute("disabled", true)
                                        : x.removeAttribute("disabled")
                                );

                            that.onChange(evt);
                        };
                    })(e)
                );
            });
            return el;
        },
        eventCapture: ["input"], // you can use multiple events in the array
        onEvent({ elInput, component, event }) {
            let members = [];
            this.$el.find("input[type=checkbox]").each((index, ele) => {
                if (ele.checked) {
                    members.push(ele.value);
                }
            });

            if (members.length > 0) {
                component.addAttributes({ members: members.join(",") });
            } else {
                component.removeAttributes(["members"]);
            }
            if (members.includes("I")) {
                this.$el.find("div[name=div-legend-of-image]")[0].style.display =
                    "block";
            } else {
                this.$el.find("div[name=div-legend-of-image]")[0].style.display =
                    "none";
            }
        },
        // Update elements on the component change
        onUpdate({ elInput, component }) {
            const members = (component.getAttributes().members || "")
                .toUpperCase()
                .replace(/\s/g, "")
                .split(",");

            this.$el.find("input[type=checkbox]").each((index, ele) => {
                if (members.includes(ele.value)) {
                    ele.checked = true;
                }
            });
        },
    });
};

export default registerMateriaBoxMemberTrait;
