import {
    cmdClear,
    cmdCustomExportTemplate,
    cmdDeviceDesktop,
    cmdDeviceMobile,
    cmdDeviceTablet,
    cmdImport,
    cmdSaveChanges,
    cmdSelectStarterTemplate,
} from "../consts";
import {
    confirmModalClosedEvtName,
    confirmModalOpenEvtName,
    notificationCenterNotifyEvtName,
} from "constants/events-names";
import { once, trigger } from "helpers/events";

import CustomExportTemplate from "./custom-export-template";
import { NotificationProps } from "components/dialog/toast-dialog-global";
import SelectStarterTemplate from "./select-starter-template";
import openImport from "./openImport";

// const isReactComponent = (comp) => comp.isInstanceOf(`react-component`);
// const getComponentName = (comp) => comp.get(`type`);

// const walkThroughThree = (comp, dependencies) => {
//   if (comp && isReactComponent(comp)) {
//     console.log(`modulePath`, comp.get(`modulePath`));
//     dependencies.add(getComponentName(comp));
//   }
//   const childrenComponents = comp.components();
//   if (comp && childrenComponents.length > 0) {
//     return comp.components().reduce((acc, current) => {
//       return walkThroughThree(current, acc);
//     }, dependencies);
//   }

//   return dependencies;
// };

const Commands = (editor, config) => {
    const cm = editor.Commands;
    const txtConfirm = config.textCleanCanvas;

    cm.add(cmdImport, openImport(editor, config));
    cm.add(cmdCustomExportTemplate, CustomExportTemplate(editor, config));
    cm.add(cmdSelectStarterTemplate, SelectStarterTemplate(editor, config));
    cm.add(cmdDeviceDesktop, (e) => e.setDevice("Desktop"));
    cm.add(cmdDeviceTablet, (e) => e.setDevice("Tablet"));
    cm.add(cmdDeviceMobile, (e) => e.setDevice("Mobile portrait"));
    cm.add(
        cmdClear,
        (e) => window.confirm(txtConfirm) && e.runCommand("core:canvas-clear")
    );

    /*export declare type NotificationProps = {
    id?: string;
    message: string;
    where?: string;
    type: "error" | "success" | "info" | "warning";
    when?: Date;
  };*/
    cm.add(cmdSaveChanges, (e) => {
        trigger(confirmModalOpenEvtName, {
            title: "Atenção",
            message: "Salvar alterações?",
            executor: (okCancel) =>
                new Promise((resolve, reject) => {
                    resolve();
                    if (okCancel === "ok") {
                        e.store();
                    }
                }),
        });

        once(confirmModalClosedEvtName, (data) => {
            const { reason } = data.detail;
            if (reason === "ok" || reason === "cancel") {
                console.log("Confirm modal foi fechado pelo usuário!");
            }
        });
    });
};

export default Commands;
