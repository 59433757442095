import * as yup from "yup";

import { AnyObject, Maybe } from "yup/lib/types";

const isNotNullOrEmpty = (value: string | null | undefined) =>
  value !== undefined && value !== null && value?.trim() !== "";
export function isHostName(this: yup.StringSchema, msg: string) {
  return this.test({
    name: "isHostName",
    message: msg,
    test: (value: string | undefined) =>
      isNotNullOrEmpty(value) &&
      (/^[a-z0-9.-]+$/gi.test(value || "") || //without port number
        /^[a-z0-9.\-^:]+:[0-9]{1,5}$/.test(value || "")), // with port number
  });
}

yup.addMethod<yup.StringSchema>(yup.string, "isHostName", isHostName);

yup.addMethod<yup.StringSchema>(yup.string, "emptyAsUndefined", function () {
  return this.transform((value) => (value ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, "emptyAsUndefined", function () {
  return this.transform((value, originalValue) =>
    String(originalValue)?.trim() ? value : undefined
  );
});

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
    isHostName(msg: string): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): NumberSchema<TType, TContext>;
  }
}

export default yup;
