import React, { Component, useEffect, useState } from "react";

import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import NavMenuTenantUnderCreation from "./nav-menu-tenant-under-creation";

export const Layout = ({ children }) => {
  const [state, setState] = useState({ forecasts: [], loading: true });

  useEffect(() => {}, []);
  return (
    <div>
      <NavMenu />
      {children}
    </div>
  );
};
