import { layoutBuilderLoad, layoutBuilderSave } from "constants/api-routes";
import { storageKeys, storageRemote } from "../consts";

import { SecureHttpClient } from "services/http-client-service";
import clock from "services/clock-service";
import { createId } from "grapesjs/src/utils/mixins";
import { scapeString } from "utils/strings-utils";

const setIdForAllComponentsIfNotPresent = (c) => {
  const hasId = !!(c.attributes && c.attributes.id);
  const id = hasId ? c.attributes.id : createId(3);
  c.attributes = { id, ...(c.attributes || {}) };
  c.components &&
    c.components.forEach((c) => setIdForAllComponentsIfNotPresent(c));
};

const StorageRemote = (editor, config) => {
  const sm = editor.StorageManager;
  console.warn(`Registering ${storageRemote} ...`);
  sm.add(storageRemote, {
    /**
     * Load the data
     * @param  {Array} keys Array containing values to load, eg, ['gjs-components', 'gjs-styles', ...]
     * @param  {Function} clb Callback function to call when the load is ended
     * @param  {Function} clbErr Callback function to call in case of errors
     */
    load(keys, clb, clbErr) {
      const { documentId } = sm.getConfig();
      const isNewLayout = documentId === null || documentId === undefined;
      if (isNewLayout) {
        clb({});
      } else {
        SecureHttpClient.Get(layoutBuilderLoad(documentId))
          .then((data) => {
            const result = keys.reduce(
              (acc, key) => ({ ...acc, [key]: data[key] }),
              {}
            );
            clb(result);
          })
          .catch((err) => clbErr(err));
      }
    },

    /**
     * Store the data
     * @param  {Object} data Data object to store
     * @param  {Function} clb Callback function to call when the load is ended
     * @param  {Function} clbErr Callback function to call in case of errors
     */
    store(data, clb, clbErr) {
      const { documentId, dmLocal } = sm.getConfig();
      console.log("documentId", documentId);
      console.log("dmLocal", dmLocal);
      const components = JSON.parse(data[storageKeys.components]);
      components.forEach((c) => setIdForAllComponentsIfNotPresent(c));
      data[storageKeys.components] = JSON.stringify(components);
      data[storageKeys.delta] = data[storageKeys.delta] || clock.currentUTCDate;

      SecureHttpClient.Post(
        layoutBuilderSave(dmLocal, documentId || ""),
        scapeString(JSON.stringify(data))
      )
        .then(async (response) => {
          const { LayoutId } = await response.json();
          clb(LayoutId);
        })
        .catch((err) => clbErr(err));
    },
  });
};

export default StorageRemote;
