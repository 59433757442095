import * as signalR from '@microsoft/signalr'

import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr'

import { TenantIdHeaderKey } from 'constants/headers-keys'
import { ThrowError } from 'utils/exception-utils'
import authService from 'services/authorize-service'
import { currentTenantKey } from 'constants/session-storage-keys'

const BASE_URL =
    process.env.REACT_APP_HUB_BASE_ADDRESS ?? ThrowError('HUB_BASE_ADDRESS_NOT_DEFINED_IN_ENVIRONMENT_VARIABLES') //or whatever your backend port is

class CustomHttpClient extends DefaultHttpClient {
    private _customHeaders?: Record<string, string>
    /**
     *
     */
    constructor(customHeaders?: Record<string, string>) {
        super(console)
        this._customHeaders = customHeaders // headers doesn't works throught webSocket =/
    }
    public send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = { ...request.headers, ...(this._customHeaders || {}) }
        return super.send(request)
    }
}

export abstract class Connector {
    private _connection: signalR.HubConnection
    private _onConnectedCallbacks: [resolve: () => void, reject: (e: Error) => void][] = []
    private _isConnected = false
    private _initPromise: Promise<void> | null = null
    protected SubscribeTo(eventName: string, callback: (...params: any[]) => void) {
        this._connection.on(eventName, callback)
    }

    protected Invoke(eventName: string, ...args: unknown[]) {
        this._connection.invoke(eventName, ...args)
    }

    public UnSubscribeTo(eventName: string, callback: (...params: any[]) => void) {
        this._connection.off(eventName, callback)
    }

    public JoinToGroup(groupName: string) {
        return this._connection.invoke('JoinToGroup', groupName)
    }

    public UnJoinFromGroup(groupName: string) {
        if (!this._isConnected) return
        return this._connection.invoke('UnJoinFromGroup', groupName)
    }

    public OnConnected() {
        return (this._initPromise ??= new Promise<void>((resolve, reject) =>
            this._onConnectedCallbacks.push([resolve, reject])
        ))
        // if (this._isConnected) return Promise.resolve()
        // var promise = new Promise<void>((resolve, reject) => this._onConnectedCallbacks.push([resolve, reject]))

        // return promise
    }

    public IsConnected() {
        return this._isConnected
    }

    constructor(path: string) {
        const jsonPayload = window.sessionStorage.getItem(currentTenantKey)
        const currentTenant = jsonPayload ? JSON.parse(jsonPayload) : null
        console.log('[SignalR]: currentTenant?.id ', currentTenant?.id)

        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(BASE_URL + path, {
                httpClient: new CustomHttpClient({
                    ...((currentTenant?.id && {
                        [TenantIdHeaderKey]: currentTenant.id
                    }) ||
                        ThrowError('MISSING_TENANT_ID'))
                }),
                accessTokenFactory: async () => (await authService.getAccessToken()) || '',
                headers: {
                    ...((currentTenant?.id && {
                        [TenantIdHeaderKey]: currentTenant.id
                    }) ||
                        ThrowError('MISSING_TENANT_ID'))
                }
            })
            .configureLogging(process.env.NODE_ENV !== 'production' ? signalR.LogLevel.Debug : signalR.LogLevel.None)
            .withAutomaticReconnect()
            .build()
        this._connection
            .start()
            .then(() => {
                this._isConnected = true
                this._onConnectedCallbacks.forEach(([resolve, _]) => resolve())
            })
            .catch(err => {
                this._onConnectedCallbacks.forEach(([_, reject]) => reject(err))
                console.error('SIGNALR_CONNECTION_ERROR', err)
            })
    }
}
