/**
 * Parse an given object to an array turning each property as a object of type
 * {key: string, value: any} inside of array.
 * ex.
 *  {prop1: 'value1', prop2: true} =>
 *  [{key: 'prop1', value: 'value1'}, {key: 'prop2', value: true}]
 * @param  {any} obj
 * @param  {boolean} keyAsLowerCased=false
 */
export function MapObjToArray(obj: any, keyAsLowerCased = false) {
  return Object.keys(obj).map((key) => {
    const normalizedKey = keyAsLowerCased ? key.toLowerCase() : key;
    return {
      key: normalizedKey,
      value: obj[key],
    };
  });
}

/**
 * Determine if a given value is an Object (not null)
 * @param  {unknown} value
 */
export function IsObject(value: unknown): boolean {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}
