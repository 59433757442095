import { FormContext, UnformContext } from "@unform/core";
import { FormGroup, Input, Label } from "reactstrap";
import React, { ChangeEvent, useContext, useEffect, useRef } from "react";

import ActFormItemLayout from "./form-item-layout";
import { FormInputProps } from "@/types/form/input";
import { useField } from "@unform/core";

declare type SelectOptionItem = {
  name: string;
  value: string;
  selected?: boolean;
};
declare interface SelectOptions extends FormInputProps {
  name: string;
  label: string;
  options: SelectOptionItem[];
  multiple?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  onChange?: (evt: ChangeEvent<HTMLInputElement> | undefined) => void;
}

const Select: React.FC<SelectOptions> = ({
  name,
  label,
  options,
  multiple = false,
  readOnly = false,
  onChange = undefined,
  defaultValue: attrDefaultValue = undefined,
  required,
  description,
  helpText,
}) => {
  const inputRef = useRef(null);
  const { unregisterField } = useContext<UnformContext>(FormContext);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      // getValue(ref: any) {
      //   if (name === "DatabaseEngine")
      //     console.log("DatabaseEngine", ref.innerRef);
      //   return ref.value;
      // },
      setValue(ref: any, value: string) {
        ref.value = value;
      },
      // clearValue(ref: any) {
      //   ref.setInputValue("");
      // },
    });
    //return () => unregisterField(fieldName); // <= this really unregister the field
  }, [fieldName, registerField, unregisterField]);

  return (
    <ActFormItemLayout {...{ name, label, required, description, helpText }}>
      <Input
        className={error ? "form-control is-invalid" : "form-control"}
        innerRef={inputRef}
        type="select"
        name={fieldName}
        multiple={multiple}
        readOnly={readOnly}
        disabled={readOnly}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </Input>
    </ActFormItemLayout>
  );
};

export default Select;
