import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useCallback, useContext, useMemo } from "react";

import { FormItemLayoutType } from "@/types/form/form-item-layout";
import { HelpCircle } from "react-feather";
import cn from "classnames";
import { uniqueId } from "lodash";
import { useField } from "@unform/core";

const ActFormItemLayout: FormItemLayoutType = ({
  name,
  label,
  required,
  description,
  helpText,
  helpTextPlacement = "tooltip",
  children,
}) => {
  const { fieldName, error } = useField(name);
  const ariaDescribeId = useMemo(() => uniqueId(), []);
  const helpTextId = useMemo(() => uniqueId(), []);

  const renderTooltip = useCallback(
    (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
      <Tooltip id="button-tooltip" {...props}>
        {helpText}
      </Tooltip>
    ),
    [helpText]
  );

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {required && (
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          )}
        </label>
      )}
      {helpText && helpTextPlacement === "tooltip" && (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <HelpCircle
            size={16}
            className="mr-75"
            style={{ marginLeft: "5px", color: "#1575BB", cursor: "pointer" }}
          />
        </OverlayTrigger>
      )}

      {children}
      {error &&
        error.split("\n").map((e) => (
          <div key={uniqueId()} className="invalid-feedback">
            {e}
          </div>
        ))}
      {description && (
        <small
          id={ariaDescribeId}
          className={cn("form-text text-muted d-none")}
        >
          {description}
        </small>
      )}
      {helpText && helpTextPlacement === "inline" && (
        <small id={helpTextId} className="form-text text-muted">
          {helpText}
        </small>
      )}
    </div>
  );
};

export default ActFormItemLayout;
