import RodapePortal from "components/common/rodape";
import { createId } from "grapesjs/src/utils/mixins";
import { trigger } from "helpers/events";
import {
    confirmModalOpenEvtName,
    notificationCenterNotifyEvtName,
} from "constants/events-names";

const RegisterRodape = (editor, config = null) => {
    editor.Components.addType("RodapePortal", {
        extend: "react-component",
        model: {
            defaults: {
                component: RodapePortal,
                modulePath: "@/components/common/rodape",
                fetcher: {
                    path: "@/providers/rodape-server-data-provider",
                    moduleName: "RodapeDataProvider",
                    method: "GetRodapeSingleAsync",
                },
                stylable: true,
                resizable: true,
                editable: true,
                draggable: true,
                droppable: true,
                attributes: {
                    "display-mode": "modelo1",
                },
                traits: [
                    {
                        type: "select",
                        label: "1 &#45; Modo de exibição",
                        name: "display-mode",
                        options: [
                            { id: "modelo1", name: "Modelo 1" },
                            { id: "modelo2", name: "Modelo 2" },
                            { id: "modelo3", name: "Modelo 3" },
                        ]
                    }
                ],
            },
            init: function (...args) {
                const newId = createId();

                const { id } = this.getAttributes();
                this.addAttributes({ id: id || this.getId() || newId });

                this.on("change:attributes:display-mode", this.handleDisplayModeChange);
            },
            handleDisplayModeChange() {
                const displayMode = this.getAttributes()["display-mode"];
                const comp = editor.getSelected();
                trigger(confirmModalOpenEvtName, {
                    title: "Atenção",
                    message:
                        `Confirma o carregamento do layout selecionado? Esta ação irá limpar totalmente o conteúdo atual em edição do rodapé.`,
                    executor: (okCancel) =>
                        new Promise((resolve, reject) => {
                            if (okCancel === "ok") {

                                switch (displayMode?.toLowerCase()) {
                                    case "modelo1":
                                        comp.components(`
  <footer class="d-flex flex-wrap justify-content-between align-items-center footer-portal py-20">
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between align-items-center">
          <span class="mb-0 w-100 text-light">
            <strong>NOME</strong><br /><br />
            <span>Endereço: Endereco<br />
              Telefone: (00) 1234-5678 / (00) 1234-5678
            </span>
          </span>
        </div>
      </div>
      <div class="row mt-5">
            <div class="col">
                <a href="http://www.actcon.com.br" target="_blank" title="Ir para o site da Actcon.net" class="rdp_empresa" rel="noreferrer">Desenvolvido por Actcon.net</a>
            </div>
            <div class="col">
            <a href="/" class="rdp_portal pull-right">
                <img src="${window.location.origin}/styles/images/logo_portal.webp" alt="Portal Fácil" title="Ir para o site do Portal Fácil"  rel="noreferrer" />
            </a>
            </div>
      </div>
    </div>
  </footer>`);
                                        break;
                                    case "modelo2":
                                        comp.components(`<footer class="d-flex flex-wrap justify-content-between align-items-center footer-portal py-20">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
            <div class="col-12 col-lg-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
            <div class="col-12 col-lg-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
            <div class="col-12 col-lg-3">
                <h5>Social</h5>
                <div class="social-icons">
                    <a title="facebook" class="btn btn-dark" href="https://www.facebook.com/" target="_blank" ><i class="bi bi-facebook"></i></a>
                    <a title="instagram" class="btn btn-dark" href="https://www.instagram.com/" target="_blank" ><i class="bi bi-instagram"></i></a>
                    <a title="youtube" class="btn btn-dark" href="https://www.youtube.com/" target="_blank" ><i class="bi bi-youtube"></i></a>
                    <a title="twitter" class="btn btn-dark" href="https://twitter.com/" target="_blank" ><i class="bi bi-twitter"></i></a>
                    <a title="whatsapp" class="btn btn-dark" href="https://wa.me/5501123456789" target="_blank"><i class="bi bi-whatsapp"></i></a>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
                <span class="mb-0 w-100 text-light">
                    <strong>NOME</strong><br /><br />
                    <span>Endereço: Endereco<br />
                       Telefone: (00) 1234-5678 / (00) 1234-5678
                    </span>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <a href="http://www.actcon.com.br" target="_blank" title="Ir para o site da Actcon.net" class="rdp_empresa" rel="noreferrer">Desenvolvido por Actcon.net</a>
            </div>
            <div class="col">
            <a href="/" class="rdp_portal pull-right">
                <img src="${window.location.origin}/styles/images/logo_portal.webp" alt="Portal Fácil" title="Ir para o site do Portal Fácil"  rel="noreferrer" />
            </a>
            </div>
        </div>
    </div>
  </footer>`);
                                        break;
                                    case "modelo3":
                                        comp.components(`<footer class="d-flex flex-wrap justify-content-between align-items-center footer-portal py-20">
    <div class="container">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5">
            <div class="col mb-3">
            <a href="/" class="d-flex align-items-center mb-3 link-dark text-decoration-none">
                <img src="${window.location.origin}/styles/images/logo_portal.webp" alt="..." />
            </a>
            <div class="social-icons mt-3 mb-3">
                    <a title="facebook" class="btn btn-dark" href="https://www.facebook.com/" target="_blank" ><i class="bi bi-facebook"></i></a>
                    <a title="instagram" class="btn btn-dark" href="https://www.instagram.com/" target="_blank" ><i class="bi bi-instagram"></i></a>
                    <a title="youtube" class="btn btn-dark" href="https://www.youtube.com/" target="_blank" ><i class="bi bi-youtube"></i></a>
                    <a title="twitter" class="btn btn-dark" href="https://twitter.com/" target="_blank" ><i class="bi bi-twitter"></i></a>
                    <a title="whatsapp" class="btn btn-dark" href="https://wa.me/5501123456789" target="_blank"><i class="bi bi-whatsapp"></i></a>
            </div>
            <p><span class="mb-0 w-100 text-light">
                <strong>NOME</strong>
                <br /><br />
                <span>Endereço: Endereco<br />
                   Telefone: (00) 1234-5678 / (00) 1234-5678
                 </span>
            </span></p>
            </div>
            <div class="col mb-3">
            </div>
            <div class="col mb-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
            <div class="col mb-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
            <div class="col mb-3">
                <h5>Section</h5>
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Home</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Features</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">Pricing</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0">About</a></li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <a href="http://www.actcon.com.br" target="_blank" title="Ir para o site da Actcon.net" class="rdp_empresa" rel="noreferrer">Desenvolvido por Actcon.net</a>
            </div>
            <div class="col">
            <a href="/" class="rdp_portal pull-right">
                <img src="${window.location.origin}/styles/images/logo_portal.webp" alt="Portal Fácil" title="Ir para o site do Portal Fácil"  rel="noreferrer" />
            </a>
            </div>
        </div>
    </div>
</footer>`);
                                        break;
                                    default:
                                        break;
                                }
                                resolve();
                                trigger(notificationCenterNotifyEvtName, {
                                    message: "Layout carregado com sucesso!",
                                    type: "success",
                                });
                            } else {
                                resolve();
                            }
                        }),
                });
            },
        },
        isComponent: (el) => el.tagName === "RODAPEPORTAL",
    });

    editor.BlockManager.add("RodapePortal", {
        label: "<div class='gjs-fonts gjs-f-b1'>Rodapé</div>",
        category: "React Components",
        content: `<RodapePortal>
        <footer class="d-flex flex-wrap justify-content-between align-items-center footer-portal py-20">
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between align-items-center">
         <span class="mb-0 w-100 text-light">
                <strong>NOME</strong>
                <br /><br />
                <span>Endereço: Endereco<br />
                   Telefone: (00) 1234-5678 / (00) 1234-5678
                 </span>
            </span>
        </div>
      </div>
        <div class="row mt-5">
            <div class="col">
                <a href="http://www.actcon.com.br" target="_blank" title="Ir para o site da Actcon.net" class="rdp_empresa" rel="noreferrer">Desenvolvido por Actcon.net</a>
            </div>
            <div class="col">
            <a href="/" class="rdp_portal pull-right">
                <img src="${window.location.origin}/styles/images/logo_portal.webp" alt="Portal Fácil" title="Ir para o site do Portal Fácil"  rel="noreferrer" />
            </a>
            </div>
        </div>
    </div>
  </footer>
    </RodapePortal>`,
    });
};

export default RegisterRodape;
