import './index.scss'

import EvolutionProgressBarLabel from './status-label'
import { EvolutionProgressType } from 'types/components/common/evolution-progress-bar'
import cn from 'classnames'

const EvolutionProgressBar: EvolutionProgressType = ({ steps, title, subtitle }) => {
    return (
        <div className="container">
            <div className="row text-center justify-content-center mb-5">
                <div className="col-xl-6 col-lg-8">
                    <h2 className="font-weight-bold">{title}</h2>
                    <p className="text-muted">{subtitle}</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                        {steps.map(({ status, id, title }) => (
                            <div key={id} className="timeline-step">
                                <div className="timeline-content">
                                    <div className={cn('inner-circle', `inner-circle-${status}`)} />
                                    <p className="h6 mt-3 mb-1">{title}</p>
                                    <EvolutionProgressBarLabel status={status} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvolutionProgressBar
