import { Button, Container } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'

import AppContext from '../contexts/app-context'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { selectTenantModalOpen as openModalSelectTenantEvt } from '../constants/events-names'
import { parseUrlTemplate } from 'utils/strings-utils'
import { tenantInsertUpdateRoute } from 'constants/app-routes'
import { trigger } from '../helpers/events'
import { useContext } from 'react'

export const Home = () => {
    const { currentTenant } = useContext(AppContext)

    const history = useHistory()
    const navigation = useLocation()
    return (
        <Container>
            <h1>Olá!</h1>
            <p>Bem-vindo ao BackOffice do Portal Fácil, aqui você poderá:</p>
            <div className="row">
                <div className="col-6">
                    {currentTenant.is_sub_tenant ? (
                        <Button
                            color="primary"
                            onClick={() =>
                                history.push(
                                    parseUrlTemplate(tenantInsertUpdateRoute, {
                                        action: 'new'
                                    })
                                )
                            }
                        >
                            Criar um novo Cliente
                        </Button>
                    ) : (
                        <DropdownButton id="dropdown-basic-button" title="Criar novo" style={{ display: 'inline' }}>
                            <Dropdown.Item
                                onClick={() =>
                                    history.push(
                                        parseUrlTemplate(tenantInsertUpdateRoute, {
                                            action: 'new'
                                        })
                                    )
                                }
                            >
                                Cliente
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    history.push(
                                        parseUrlTemplate(tenantInsertUpdateRoute, {
                                            action: 'new-subsite'
                                        })
                                    )
                                }
                            >
                                Sub-site
                            </Dropdown.Item>
                        </DropdownButton>
                    )}
                    {' Ou '}
                    <Button color="primary" onClick={() => trigger(openModalSelectTenantEvt)}>{`${
                        currentTenant !== undefined ? 'Alterar o Cliente' : 'Selecionar um Cliente'
                    }`}</Button>
                </div>
            </div>
        </Container>
    )
}
