import { LocalTenantType } from '@/types/tenant/tenant-local-type'
import { SecureHttpClient } from './http-client-service'
import { TenantConfig } from 'actcon-portal-types'
import { currentTenantKey } from '../constants/session-storage-keys'
import { tenantGetById } from 'constants/api-routes'

class TenantService {
    _storage: Storage
    private static _instance: TenantService
    private _changeWatchers: Set<(newTenant: LocalTenantType | null) => void> = new Set()
    constructor() {
        this._storage = window.sessionStorage
    }
    GetTenant(): LocalTenantType | null {
        const jsonPayload = this._storage.getItem(currentTenantKey)
        const currentTenant = jsonPayload ? JSON.parse(jsonPayload) : null
        return currentTenant
    }
    SwitchTenant(targetTenantId: string) {
        return new Promise<LocalTenantType | never>((resolve, reject) => {
            if (targetTenantId === null || targetTenantId === undefined || targetTenantId?.trim() === '') {
                reject('MISSING_TARGET_TENANT_ID')
            } else {
                SecureHttpClient.Get<TenantConfig>(tenantGetById(targetTenantId))
                    .then(r => {
                        const newTenant = this.MapFromServerToLocal(r)
                        this.Set(newTenant)
                        resolve(newTenant)
                    })
                    .catch(reject)
            }
        })
    }
    MapFromServerToLocal(tenantServerResponse: TenantConfig & { IsSubSite?: boolean }): LocalTenantType {
        const localType: LocalTenantType = {
            id: tenantServerResponse.TenantId,
            name: tenantServerResponse.Name,
            is_sub_tenant: tenantServerResponse.IsSubSite ?? tenantServerResponse.IsIntranet ?? false,
            in_creation: tenantServerResponse.InCreation,
            steps_completed: tenantServerResponse.StepsCompleted,
            step1_tasks_completed: tenantServerResponse.Step1TasksCompleted
        }
        return localType
    }
    get TenantId(): string | null | undefined {
        return this.GetTenant()?.id
    }
    get TenantName(): string | null | undefined {
        return this.GetTenant()?.name
    }
    get IsTenantDefined(): boolean {
        return this.TenantId !== undefined && this.TenantId != null
    }
    get IsUnderCreation(): boolean {
        return false
    }
    get IsSubTenant(): boolean {
        return this.GetTenant()?.is_sub_tenant === true
    }
    Reset() {
        this._storage.removeItem(currentTenantKey)
        const watchers = Array.from(this._changeWatchers).reverse()
        watchers.forEach(w => w(null))
    }
    Set(newTenant: LocalTenantType) {
        this._storage.setItem(currentTenantKey, JSON.stringify(newTenant))
        const watchers = Array.from(this._changeWatchers).reverse()
        watchers.forEach(w => w(newTenant))
    }
    WatchChangeTenant(callback: (newTenant: LocalTenantType | null) => void) {
        this._changeWatchers.add(callback)
    }
    UnWatchChangeTenant(callback: (newTenant: LocalTenantType | null) => void) {
        this._changeWatchers.delete(callback)
    }
    public static getInstance(): TenantService {
        if (!TenantService._instance) TenantService._instance = new TenantService()
        return TenantService._instance
    }

    static get instance() {
        return TenantService._instance ?? TenantService.getInstance()
    }
}

export default TenantService.getInstance
