export const cmdCustomExportTemplate = "custom-export-template",
  cmdImport = "gjs-open-import-webpage",
  cmdDeviceDesktop = "set-device-desktop",
  cmdDeviceTablet = "set-device-tablet",
  cmdDeviceMobile = "set-device-mobile",
  cmdClear = "canvas-clear",
  cmdSaveChanges = "save-changes",
  cmdSelectStarterTemplate = "select-starter-template",
  storageLocal = "storage-local",
  storageRemote = "storage-remote",
  storageLocalRemote = "storage-local-remote",
  storageKeysPrefix = "gjs-",
  storageKeys = {
    html: `${storageKeysPrefix}html`,
    components: `${storageKeysPrefix}components`,
    assets: `${storageKeysPrefix}assets`,
    css: `${storageKeysPrefix}css`,
    styles: `${storageKeysPrefix}styles`,
    delta: `${storageKeysPrefix}delta`,
  };
