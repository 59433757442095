import { Col, Container, Row, Spinner } from 'reactstrap'
import { CreationProgressType, MessageParams } from '@/types/contexts/tenant-creation-context'
import { useEffect, useState } from 'react'

import { DmStatusTenantCreationEnum } from 'actcon-portal-types'
import Loading from 'components/Loading'
import MessageDialog from 'components/dialog/message-dialog'
import Nav from 'react-bootstrap/Nav'
import { SecureHttpClient } from 'services/http-client-service'
import Tab from 'react-bootstrap/Tab'
import { TenantCreationProvider } from 'contexts/tenant-creation-context'
import { TenantCreationRouteParams } from 'types/route-params/tenant-creation-route-params'
import TenantCreationStep1 from './step1'
import TenantCreationStep2 from './step2'
import TenantCreationStep3 from './step3'
import TenantCreationStep4 from './step4'
import { TenantModel } from 'types/tenant/tenant-model-type'
import TenantService from 'services/tenant-service'
import classNames from 'classnames'
import { schema } from './step1/form-schema'
import { tenantsLoad } from 'constants/api-routes'
import useCurrentTenant from '../../hooks/use-current-tenant'
import { useParams } from 'react-router-dom'

const TenantCreationForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { action } = useParams<TenantCreationRouteParams>()
    const isInsert = action === 'new' || action === 'new-subsite'
    const isSubTenantCreateAction = action === 'new-subsite'
    const [isSubSite, setIsSubSite] = useState<boolean>(isSubTenantCreateAction)
    const [, setError] = useState(true)
    const [key, setKey] = useState<string>('first-step')
    const [tenantModel, setTenantModel] = useState<TenantModel | undefined>()
    const [isShowingMessage, setIsShowingMessage] = useState(false)
    const [messageParams, setMessageParams] = useState<MessageParams | null>(null)
    const [creationProgress, updateCreationProgress] = useState<CreationProgressType>({
        DmCreationStatus: DmStatusTenantCreationEnum.Unknown,
        StepsCompleted: [],
        Step1TasksCompleted: []
    })
    //const { current: currentTenant } = useCurrentTenant()

    useEffect(() => {
        console.log(`TENANT CREATION ROOT PAGE, INITIALIZING AT ${new Date()}`)
        setIsLoading(true)

        SecureHttpClient.Get<TenantModel>(tenantsLoad)
            .then(data => {
                if (isInsert) {
                    setTenantModel({
                        ...schema.getDefault(),
                        ParentTenantId: isSubTenantCreateAction ? TenantService().TenantId : undefined,
                        ParentName: isSubTenantCreateAction ? TenantService().TenantName : 'Nenhum',
                        DatabaseEngine: isSubTenantCreateAction ? data.DatabaseEngine : undefined,
                        IsSubSite: isSubTenantCreateAction
                    } as unknown as TenantModel)
                } else {
                    setIsSubSite(data.IsSubSite)
                    setTenantModel(data)
                    updateCreationProgress({
                        DmCreationStatus: data.DmCreationStatus,
                        StepsCompleted: data.StepsCompleted || [],
                        Step1TasksCompleted: data.Step1TasksCompleted || []
                    })
                }
            })
            .catch(err => {
                setError(err)
            })
            .finally(() => setIsLoading(false))
    }, [isSubTenantCreateAction, isInsert])

    return (
        <Container className="tenant-creation">
            <Loading isLoading={isLoading} message="Aguarde..." />
            <MessageDialog
                isOpen={isShowingMessage}
                message={messageParams?.message}
                handleButton={messageParams?.buttonCallback}
            />
            <TenantCreationProvider
                value={{
                    data: tenantModel,
                    isInsert,
                    isSubSite,
                    setIsLoading,
                    setTenantModel,
                    isShowingMessage,
                    setIsShowingMessage,
                    messageParams,
                    setMessageParams,
                    creationProgress,
                    updateCreationProgress
                }}
            >
                <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first-step"
                    activeKey={key}
                    onSelect={k => k && setKey(k)}
                >
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first-step">
                                        <span className="position-relative py-2">
                                            Passo 1{' '}
                                            {creationProgress.StepsCompleted.includes(1) && (
                                                <i
                                                    className={classNames('bi bi-check-circle', {
                                                        'text-light': key === 'first-step',
                                                        'text-success': key !== 'first-step'
                                                    })}
                                                ></i>
                                            )}
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second-step">
                                        Passo 2{' '}
                                        {creationProgress.StepsCompleted.includes(2) && (
                                            <i
                                                className={classNames('bi bi-check-circle', {
                                                    'text-light': key === 'second-step',
                                                    'text-success': key !== 'second-step'
                                                })}
                                            ></i>
                                        )}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third-step">
                                        Passo 3{' '}
                                        {creationProgress.StepsCompleted.includes(3) && (
                                            <i
                                                className={classNames('bi bi-check-circle', {
                                                    'text-light': key === 'third-step',
                                                    'text-success': key !== 'third-step'
                                                })}
                                            ></i>
                                        )}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth-step">
                                        Passo 4{' '}
                                        {creationProgress.StepsCompleted.includes(4) && (
                                            <i
                                                className={classNames('bi bi-check-circle', {
                                                    'text-light': key === 'fourth-step',
                                                    'text-success': key !== 'fourth-step'
                                                })}
                                            ></i>
                                        )}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first-step">
                                    <Container>
                                        <TenantCreationStep1 />
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second-step">
                                    <Container>
                                        <TenantCreationStep2 />
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third-step">
                                    <Container>
                                        <TenantCreationStep3 />
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth-step">
                                    <Container>
                                        <TenantCreationStep4 />
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </TenantCreationProvider>
        </Container>
    )
}

export default TenantCreationForm
