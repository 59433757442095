import { createId } from "grapesjs/src/utils/mixins";

export const defaultSizeMateriaItems = 5;
export const defaultSizeBannerItems = 1;
export const defaultSizeYoutubeItems = 5;
export const defaultDateFormatItems = "dd/MM/yyyy";
export const defaultSizeLengthItems = 30;


export const idsTrait = {
    id: createId(),
    type: "text",       // If you don't specify the type, the `text` is the default one
    name: "ids",        // Required and available for all traits
    label: "2.1 &#45; Códigos",   // The label you will see near the input
    placeholder: "Separados por vírgula", // Placeholder to show inside the input
};

export const idsCategoriaTrait = {
    id: createId(),
    type: "text",           // If you don't specify the type, the `text` is the default one
    name: "ids-categoria",  // Required and available for all traits
    label: "2.2 &#45; Categorias",    // The label you will see near the input
    placeholder: "Separados por vírgula", // Placeholder to show inside the input
};

export const countTraint = {
    id: createId(),
    type: "number",
    placeholder: "1-10",
    min: 1,     // Minimum number value
    max: 10,    // Maximum number value
    step: 1,    // Number of steps
    label: '2.1 &#45; Quantidade de registros',
    name: "size",
};

export const titleLengthTraint = {
    id: createId(),
    type: "number",
    placeholder: "1-100",
    min: 1,     // Minimum number value
    max: 100,   // Maximum number value
    step: 1,    // Number of steps
    label: '3.1 &#45; Tamanho do título',
    name: "length-title" 
};

export const headlineLengthTraint = {
    id: createId(),
    type: "number",
    placeholder: "1-300",
    min: 1,     // Minimum number value
    max: 300,   // Maximum number value
    step: 1,    // Number of steps
    label: '3.2 &#45; Tamanho da chamada',
    name: "length-headline",
};

export const legendImageLengthTraint = {
    id: createId(),
    type: "number",
    placeholder: "1-500",
    min: 1,     // Minimum number value
    max: 500,   // Maximum number value
    step: 1,    // Number of steps
    label: '3.5 &#45; Tamanho da legenda da imagem',
    name: "length-legend-image",
};

export const headerListShowTraint = {
    id: createId(),
    type: "checkbox",
    name: "header-list-show",
    label: '1.1 &#45; Exibir cabeçalho na lista',
    valueTrue: true,
    valueFalse: false 
};

export const headerListTextTrait = {
    id: createId(),
    type: "text",               // If you don't specify the type, the `text` is the default one
    name: "header-list-text",   // Required and available for all traits
    label: "1.1.1 &#45; Texto do cabeçalho da lista",           // The label you will see near the input
    placeholder: "Informe o texto", // Placeholder to show inside the input 
};