import React, { PropsWithChildren, useContext, useEffect } from 'react'

import AppContext from '../../contexts/app-context'
import { Store } from 'react-notifications-component'
import TenantCreationConnector from 'classes/signalr/tenant-creation-signalr-connection'
import useCurrentTenant from '../../hooks/use-current-tenant'

export type TenantCreationHubProps = {}

const TenantCreationHub: React.FC<PropsWithChildren<TenantCreationHubProps>> = ({ children }) => {
    const { current } = useCurrentTenant()
    useEffect(() => {
        if (!current) {
            console.log('TenantCreationHub Effect: TENANT IS NOT DEFINED, GETTING OUT')
            return
        }
        console.log('TenantCreationHub Effect: TENANT IS DEFINED, STARTING CONNECTION...')
        const hub = TenantCreationConnector()

        hub.OnConnected().then(() => {
            // hub.OnCreationProgressChanged(model => {
            //     console.log('opa, tem mensagem nova', model)
            //     Store.addNotification({
            //         title: 'Tenant Creation Progress Changed',
            //         message: model.Message,
            //         type: model.Type,
            //         insert: 'top',
            //         container: 'top-right',
            //         animationIn: ['animate__animated', 'animate__fadeIn'],
            //         animationOut: ['animate__animated', 'animate__fadeOut'],
            //         dismiss: {
            //             duration: 5000,
            //             onScreen: true,
            //             pauseOnHover: true
            //         }
            //     })
            // })

            hub.OnReceiveSystemMessage(model => {
                console.log('System message arrived!', model)
                Store.addNotification({
                    title: 'New System message!',
                    message: model as string,
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                        pauseOnHover: true
                    }
                })
            })
        })

        //setTimeout(() => hub.WatchStep(1), 5000);
    }, [current])

    return <>{children}</>
}

export default TenantCreationHub
