import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";

import { TenantIdHeaderKey } from "constants/headers-keys";
import authService from "services/authorize-service";
import { setContext } from "@apollo/client/link/context";
import tenantService from "./tenant-service";

const uri: string | undefined = process.env.REACT_APP_GRAPHQL_SERVER_URI;
if (uri === undefined) {
  throw new Error(
    "Please define your REACT_APP_GRAPHQL_SERVER_URI in .env file"
  );
}

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext(() =>
  authService.getAccessToken().then((token) => {
    return {
      // Make sure to actually set the headers here
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        [TenantIdHeaderKey]: tenantService().TenantId,
      },
    };
  })
);

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;
