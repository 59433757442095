import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { SyntheticEvent } from "react";
import { trigger } from "helpers/events";
import { useState } from "react";

declare type ConfirmDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleOkCancel: (
    e: SyntheticEvent<HTMLButtonElement>,
    okCancel: "ok" | "cancel"
  ) => void;
  isProcessing: boolean;
  title: string;
  message: string;
  closeOnClickBackdrop?: boolean;
  closeOnEscKey?: boolean;
};

export default function ConfirmDialog({
  isOpen,
  handleClose,
  handleOkCancel,
  isProcessing,
  title = "Confirmação",
  message = "",
  closeOnClickBackdrop = false,
  closeOnEscKey = false,
}: ConfirmDialogProps) {
  const internalHandleClose = () => {
    console.log("internalHandleClose fired");
    (handleClose || function () {})();
  };

  const internalHandleOkCancel = (
    e: SyntheticEvent<HTMLButtonElement>,
    okCancel: "ok" | "cancel"
  ) => {
    (handleOkCancel || function () {})(e, okCancel);
  };

  return (
    <Modal
      show={isOpen}
      onHide={internalHandleClose}
      backdrop={!closeOnClickBackdrop ? "static" : closeOnClickBackdrop}
      size="sm"
      keyboard={closeOnEscKey}
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={(e: SyntheticEvent<HTMLButtonElement>) =>
            internalHandleOkCancel(e, "cancel")
          }
          disabled={isProcessing}
        >
          Não
        </Button>
        <Button
          variant="primary"
          onClick={(e) => internalHandleOkCancel(e, "ok")}
          disabled={isProcessing}
        >
          {isProcessing && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          {isProcessing ? "Executando..." : "Sim"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
